<template>
  <div>
    <crud-screen
      :loading.sync="loading"
      :data="records"
      :columns="columns"
      :form-options="formOptions"
      :table-options="tableOptions"
      :add-template="template"
      :add-rules="validationRules"
      @row-add="onAdd"
      :edit-template="template"
      :edit-rules="validationRules"
      @row-edit="onChange"
    >
    </crud-screen>
  </div>
</template>
<script>
import Vue from "vue";

import _ from "lodash";

import { manufacturingLocationService } from "../../services/manufacturinglocation.service";
import CrudScreen from "../shared/crud/CrudScreen.vue";
import { configurationService } from "../../services/design/configuration.service";

export default Vue.extend({
  components: {
    CrudScreen,
  },
  mounted() {
    this.getRecords();
  },
  data() {
    return {
      loading: true,
      records: [],
      timeZones: [],
      calendars: [],
      columns: [
        "name",
        "country",
        "calendarName",
        "timeZoneName",
        "workDayStart",
        "workDayEnd",
        "active",
      ],
      formOptions: {
        width: "800px",
        labelWidth: "300px",
      },
      tableOptions: {
        headings: {
          calendarName: "Calendar Name",
          timeZoneName: "Time Zone",
          workDayStart: "Work Day Start",
          workDayEnd: "Work Day End",
          isActive: "Is Active",
        },
      },
      validationRules: {
        name: [
          { required: true, message: "Name is required", trigger: "blur" },
        ],
        country: [
          { required: true, message: "Country is required", trigger: "blur" },
        ],
        calendarId: [
          { required: true, message: "Calendar is required", trigger: "blur" },
        ],
        timeZone: [
          { required: true, message: "Time Zone is required", trigger: "blur" },
        ],
        workDayStart: [
          {
            required: true,
            message: "Work Day Start is required",
            trigger: "blur",
          },
        ],
        workDayEnd: [
          {
            required: true,
            message: "Work Day End is required",
            trigger: "blur",
          },
        ],
        isActive: [
          { required: true, message: "Active is required", trigger: "blur" },
        ],
        cmrpCutOffTime: [
          {
            required: true,
            message: "CMRP Cut-Off Time is required",
            trigger: "blur",
          }
        ],
        upcCutOffTime: [
          {
            required: true,
            message: "UPC Cut-Off Time is required",
            trigger: "blur",
          }
        ],
        peekCutOffTime: [
          {
            required: true,
            message: "PEEK Cut-Off Time is required",
            trigger: "blur",
          }
        ],
        umcCutOffTime: [
          {
            required: true,
            message: "UMC Cut-off Time is required",
            trigger: "blur",
          }
        ]
      },
    };
  },
  computed: {
    template() {
      return {
        id: {
          component: {
            show: false,
          },
        },
        name: {
          title: "Name",
        },
        country: {
          title: "Country",
        },
        calendarId: {
          title: "Calendar",
          component: {
            name: "el-select",
            filterable: true,
            options: this.calendars.map((c) => ({
              value: c.id,
              label: c.name,
            })),
          },
        },
        timeZone: {
          title: "Time Zone",
          component: {
            name: "el-select",
            filterable: true,
            options: this.timeZones.map((tz) => ({
              value: tz.ianaName,
              label: tz.name,
            })),
          },
        },
        workDayStart: {
          title: "Work Day Start",
          component: {
            name: "el-time-select",
            pickerOptions: {
              start: "06:00",
              end: "10:00",
            },
          },
        },
        workDayEnd: {
          title: "Work Day End",
          component: {
            name: "el-time-select",
            pickerOptions: {
              start: "14:00",
              end: "18:00",
            },
          },
        },
        isActive: {
          title: "Active",
          component: {
            name: "el-checkbox",
          },
        },
        cmrpCutOffTime: {
          title: "CMRP Cut-Off Time",
          component: {
            name: "el-time-select",
            pickerOptions: {
              start: "01:00",
              end: "23:00",
            },
          },
        },
        upcCutOffTime: {
          title: "UPC Cut-Off Time",
          component: {
            name: "el-time-select",
            pickerOptions: {
              start: "01:00",
              end: "23:00",
            },
          },
        },
        peekCutOffTime: {
          title: "PEEK Cut-Off Time",
          component: {
            name: "el-time-select",
            pickerOptions: {
              start: "01:00",
              end: "23:00",
            },
          },
        },
        umcCutOffTime: {
          title: "UMC Cut-Off Time",
          component: {
            name: "el-time-select",
            pickerOptions: {
              start: "01:00",
              end: "23:00",
            },
          },
        },
      };
    },
  },
  methods: {
    getRecords() {
      configurationService.getAllTimezones().then((response) => {
        this.timeZones = response.data;
      });

      manufacturingLocationService.loadManufacturingLocations().then((data) => {
        this.calendars = data.data.calendars;
        this.records = data.data.records.map((item) => ({
          ...item,
          timeZoneName: data.data.timeZones.find((tz) => tz.id == item.timeZone)
            .name,
          calendarName: this.calendars.find((c) => c.id == item.calendarId)
            .name,
          workDayStart: item.workDayStart.slice(0, -3),
          workDayEnd: item.workDayEnd.slice(0, -3),
          active: item.isActive.toString(),
          cmrpCutOffTime: item.cmrpCutOffTime.slice(0, -3),
          upcCutOffTime: item.upcCutOffTime.slice(0, -3),
          peekCutOffTime: item.peekCutOffTime.slice(0, -3),
          umcCutOffTime: item.umcCutOffTime.slice(0, -3),
        }));
        this.loading = false;
      });
    },
    onAdd(item, callback) {
      this.loading = true;
      manufacturingLocationService
        .addManufacturingLocationMapping(item)
        .then((data) => {
          callback(data.data);
          this.getRecords();
        });
    },
    onChange(item, callback) {
      this.loading = true;
      manufacturingLocationService
        .editManufacturingLocationMapping(item)
        .then((data) => {
          callback(data.data);
          this.getRecords();
        });
    },
  },
});
</script>
