<template>
  <el-form label-position="top" inline>
    <el-form-item :label="identifierLabelName">
      <el-select
        v-model="chosenIdentifiers"
        filterable
        remote
        multiple
        reserve-keyword
        clearable
        collapse-tags
        placeholder="None selected"
        :remote-method="searchIdentifiers"
      >
        <el-option
          v-for="item in filters.availableIdentifiers"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Status">
      <el-select
        v-model="chosenStatuses"
        multiple
        clearable
        collapse-tags
        placeholder="None selected"
        style="width: 300px"
      >
        <el-option
          v-for="item in combinedOrderStatuses"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <template slot="label"> &nbsp; </template>
      <el-button type="primary" size="small" @click="clearFilters"
        >Clear filters</el-button
      >
    </el-form-item>
    <el-form-item>
      <template slot="label"> &nbsp; </template>
      <el-button
        class="fright"
        style="margin-bottom: 5px"
        v-on:click="$emit('show-dialog')"
      >
        Add New
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { Event } from "vue-tables-2";
import { combinedOrderService } from "../../services/combinedorder.service";
import { CombinedOrderKeyType } from "./CombinedOrderKeyType";

import _ from "lodash";

export default Vue.extend({
  created() {
    this.getCombinedOrderStatuses();
  },
  props: {
    identifierLabelName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      combinedOrderKeyType: CombinedOrderKeyType,
      filters: {
        identifier: null,
        availableIdentifiers: [],
        definitionId: this.$route.query.chosenDefinition || 0,
        statusIds:
          (this.$route.query.chosenStatuses &&
            this.$route.query.chosenStatuses.map(Number)) ||
          [],
      },
    };
  },
  computed: {
    combinedOrderStatuses() {
      return this.$store.state.combinedOrder.combinedOrderStatuses;
    },
    chosenIdentifiers: {
      get: function () {
        return this.filters.identifier;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filters.identifier = null;
        } else {
          this.filters.identifier = newValue;
        }
        this.emitFilterChange("chosenIdentifiers", this.filters.identifier);
      },
    },
    chosenStatuses: {
      get: function () {
        return this.filters.statusIds;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filters.statusIds = null;
        } else {
          this.filters.statusIds = newValue;
        }
        this.emitFilterChange("chosenStatuses", this.filters.statusIds);
      },
    },
  },
  methods: {
    ...mapActions("combinedOrder", ["getCombinedOrderStatuses"]),
    emitFilterChange(filterName, newValue){
      Event.$emit("vue-tables.filter::" + filterName, newValue);
    },
    searchIdentifiers: function (query = "") {
      combinedOrderService
        .getFilteredIdentifiers(Number(this.filters.definitionId), query)
        .then((response) => {
          this.filters.availableIdentifiers = response.data;
        });
    },
    clearFilters() {
        Event.$emit('removeSorting');
        if (this.chosenStatuses.length != 0){
          this.chosenStatuses = [];
        }
        if (this.chosenIdentifiers.length != 0){
          this.chosenIdentifiers = [];
        }
    }
  },
  watch: {
    "$route.query.chosenDefinition": {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.filters.definitionId = this.$route.query.chosenDefinition || 0;
        }
      },
    },
  },
});
</script>
