<template>
  <div v-loading.fullscreen.lock="loading">
    <h1 class="details-header">All Schedules</h1>
    <el-row class="row" style="margin-top: 20px">
      <el-col :offset="2" :span="12">
        <el-form label-width="120px">
          <el-form-item label="Design products">
            <el-select
              v-model="chosenProductIds"
              multiple
              style="width: 100%"
              @change="reloadCalendar"
            >
              <el-option
                v-for="product in products"
                :key="product.id"
                :label="product.name"
                :value="product.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="View timezone">
            <el-select v-model="chosenTimezone" @change="updateTimezone">
              <el-option
                v-for="timezone in timezones"
                :key="timezone.ianaName"
                :label="timezone.name"
                :value="timezone.ianaName"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="10" v-if="userPermissions.CanManageDesignSessions">
        <div class="pull-right">
          <el-button type="primary" @click="showCreateSessionModal"
            >Create session</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-radio-group
          v-model="timeFormat24h"
          size="small"
          @change="changeTimeFormat"
        >
          <el-radio-button :label="true">24H</el-radio-button>
          <el-radio-button :label="false">12H</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row>
      <el-checkbox v-model="showSessions" @change="displaySessions"
        >Show booked design sessions</el-checkbox
      >
      <div class="fright">
        <el-checkbox v-model="showWeekends" @change="changeWeekendsMode"
          >Show weekends</el-checkbox
        >
      </div>
    </el-row>
    <FullCalendar
      v-if="users.length > 0"
      ref="allSchedulesCalendar"
      :options="calendarOptions"
    ></FullCalendar>

    <design-session-details
      ref="designSessionDetailsModal"
      @edit="openEditSessionModal"
      :visible.sync="sessionDetailsVisible"
      :loading.sync="loading"
      @deleted="reloadCalendar"
    ></design-session-details>
    <design-session-modal
      v-if="designSessionModel !== null"
      :model.sync="designSessionModel"
      :visible.sync="sessionModalVisible"
      :timezone="chosenTimezone"
      show-designer-selection
      @added="reloadCalendar"
      @updated="reloadCalendar"
    ></design-session-modal>
    <designers-availability-modal
      :visible.sync="designersAvailabilityVisible"
      :date="designersAvailabilityDate"
      :availabilities="designersAvailabilities"
    ></designers-availability-modal>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import { mapState } from "vuex";
import moment from "moment";
import "moment-timezone";

import FullCalendar from "@fullcalendar/vue";
import { DateTime } from "luxon";

import calendarOptions from "../../mixins/design/calendarOptions";

import DesignSession from "../../models/design/DesignSession";
import DesignSessionInfo from "../../models/design/DesignSessionInfo";

import { designerService } from "../../services/design/designer.service";
import { designSessionService } from "../../services/design/designsession.service";
import { manufacturingLocationService } from "../../services/manufacturinglocation.service";
import { scheduleService } from "../../services/design/schedule.service";
import { userService } from "../../services/users.service";
import { configurationService } from "../../services/design/configuration.service";

import DesignSessionModal from "./DesignSessionModal.vue";
import DesignSessionDetails from "./DesignSessionDetails.vue";
import DesignersAvailabilityModal from "./DesignersAvailabilityModal.vue";

import { DesignerCalendarItemType } from "../../enums/design/DesignerCalendarItemType";

export default Vue.extend({
  mixins: [calendarOptions],
  components: {
    FullCalendar,
    DesignSessionDetails,
    DesignSessionModal,
    DesignersAvailabilityModal,
  },
  created() {
    configurationService
      .getProducts()
      .then((response) => (this.products = response.data));
    configurationService.getTimezones().then((response) => {
      response.data.push({ name: "Local", ianaName: moment.tz.guess() });

      this.timezones = response.data;
    });

    userService.getUsers().then((response) => (this.users = response.data));
    designerService
      .getDesigners()
      .then((response) => (this.designers = response.data));
    manufacturingLocationService
      .loadActiveManufacturingLocations()
      .then(
        (response) => (this.manufacturingLocations = response.data)
      );
  },
  data() {
    return {
      DesignerCalendarItemType,
      loading: false,
      products: [],
      manufacturingLocations: [],
      users: [],
      designers: [],
      timezones: [],
      chosenProductIds: [],
      designersAvailabilities: [],
      chosenTimezone: moment.tz.guess(),
      sessionDetailsVisible: false,
      sessionModalVisible: false,
      showSessions: false,
      timeFormat24h: true,
      showWeekends: false,
      designSessionModel: null,
      designersAvailabilityVisible: false,
      designersAvailabilityDate: null,
      calendarOptions: {
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        timeZone: moment.tz.guess(),
        viewDidMount: (viewInfo) => {
          const viewName = viewInfo.view.type;

          this.calendarOptions.eventSources = [];

          if (viewName === "dayGridMonth") {
            this.calendarOptions.eventSources.push({
              events: (fetchInfo, successCallback, failureCallback) => {
                this.calendarEventSources.monthSchedule(
                  fetchInfo,
                  successCallback,
                  failureCallback
                );
              },
            });
          }

          if (viewName === "timeGridWeek" || viewName === "timeGridDay") {
            this.calendarOptions.eventSources.push({
              events: (fetchInfo, successCallback, failureCallback) => {
                this.calendarEventSources.weekSchedule(
                  fetchInfo,
                  successCallback,
                  failureCallback
                );
              },
            });
          }

          if (viewName === "listWeek") {
            this.calendarOptions.eventSources.push({
              events: (fetchInfo, successCallback, failureCallback) => {
                this.calendarEventSources.listSchedule(
                  fetchInfo,
                  successCallback,
                  failureCallback
                );
              },
            });
          }

          this.calendarOptions.eventSources.push({
            events: (fetchInfo, successCallback, failureCallback) => {
              if (this.showSessions) {
                this.calendarEventSources.sessionsSchedule(
                  fetchInfo,
                  successCallback,
                  failureCallback
                );
              } else {
                successCallback([]);
              }
            },
          });

          this.calendarOptions.eventSources.push({
            events: (fetchInfo, successCallback, failureCallback) => {
              if (this.showSessions) {
                this.calendarEventSources.preReservedDesignSessions(
                  fetchInfo,
                  successCallback,
                  failureCallback
                );
              } else {
                successCallback([]);
              }
            },
          });
        },
        eventClick: (eventClickInfo) => {
          const event = eventClickInfo.event;
          if (event.extendedProps.type === DesignerCalendarItemType.Session || event.extendedProps.type === DesignerCalendarItemType.PreReservation) {
            this.$refs.designSessionDetailsModal.open(event.extendedProps.id);
            this.sessionDetailsVisible = true;
          }
          if (
            event.extendedProps.type === DesignerCalendarItemType.Availability
          ) {
            this.loading = true;
            const start = DateTime.fromISO(event.startStr)
              .setZone(this.chosenTimezone)
              .startOf("day");
            const end = DateTime.fromISO(event.startStr)
              .setZone(this.chosenTimezone)
              .plus({ days: 1 })
              .startOf("day");
            this.designersAvailabilityDate = event.start;
            scheduleService
              .getAvailabilities(
                start.toUTC().toISO(),
                end.toUTC().toISO(),
                this.chosenProductIds
              )
              .then((response) => {
                this.availabilityDetailsList = [];
                this.availablilityDetailsDate = event.start;
                this.designersAvailabilities = response.data.map((a) => {
                  const designer = this.designers.find(
                    (d) => d.id === a.designerId
                  );
                  const user = this.users.find((d) => d.id === a.designerId);
                  const manufacturingLocation =
                    this.manufacturingLocations.find(
                      (d) => d.id === designer.locationId
                    );

                  const designerProducts = this.products.filter((p) => {
                    const isAssigned =
                      designer.productIds.find((dp) => dp == p.id) !==
                      undefined;

                    return isAssigned;
                  });

                  return {
                    start: a.startDate,
                    end: a.endDate,
                    designerName: `${user.firstName} ${user.lastName}`,
                    locationName: manufacturingLocation.name,
                    products: designerProducts.map((p) => p.name).join(", "),
                  };
                });
                this.designersAvailabilityVisible = true;
                this.loading = false;
              });
          }
        },
        eventSources: [],
      },
    };
  },
  methods: {
    openEditSessionModal(id) {
      designSessionService.getSession(id).then((response) => {
        this.designSessionModel = new DesignSession(response.data);
        this.sessionDetailsVisible = false;
        this.sessionModalVisible = true;
      });
    },
    updateTimezone(timezone) {
      moment.tz.setDefault(timezone);
      this.calendarOptions.timeZone = timezone;
    },
    reloadCalendar() {
      this.$refs.allSchedulesCalendar.getApi().refetchEvents();
    },
    showCreateSessionModal() {
      this.designSessionModel = new DesignSession();
      this.sessionModalVisible = true;
    },
    changeTimeFormat() {
      this.calendarOptions.eventTimeFormat = {
        ...this.calendarOptions.eventTimeFormat,
        hour12: !this.timeFormat24h,
      };

      this.calendarOptions.slotLabelFormat = {
        ...this.calendarOptions.slotLabelFormat,
        hour12: !this.timeFormat24h,
      };
    },
    changeWeekendsMode() {
      this.calendarOptions.weekends = this.showWeekends;
    },
    transformDesignerSchedules(data) {
      let events = [];

      data.forEach((item) => {
        const user = this.users.find((u) => u.id == item.designerId);
        const designerSymbol = (user && user.symbol) || "undefined";

        const designerEvents = item.schedule.availabilities
          .filter((a) => !a.sessionsLimitReached)
          .map((a) => {
            return {
              start: a.startDate,
              end: a.endDate,
              title: `${designerSymbol}\n${item.products
                .map((p) => p.name)
                .join("\n")}`,
              color: "#008000",
              extendedProps: {
                id: a.id,
                type: DesignerCalendarItemType.Availability,
              },
            };
          });

        events.push(...designerEvents);
      });

      return events;
    },
    displaySessions() {
      this.$refs.allSchedulesCalendar.getApi().refetchEvents();
    },
  },
  computed: {
    ...mapState("permissions", ["userPermissions"]),
    calendarEventSources() {
      return {
        weekSchedule: (fetchInfo, successCallback, failureCallback) => {
          this.loading = true;

          const start = DateTime.fromISO(fetchInfo.startStr).toUTC().toISO();
          const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();
          scheduleService
            .getDesignersSchedule(start, end, this.chosenProductIds)
            .then((response) => {
              const events = this.transformDesignerSchedules(response.data);
              successCallback(events);
              this.loading = false;
            });
        },
        monthSchedule: (fetchInfo, successCallback, failureCallback) => {
          this.loading = true;
          const start = DateTime.fromISO(fetchInfo.startStr).toUTC().toISO();
          const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();
          scheduleService
            .getAvailabilities(start, end, this.chosenProductIds)
            .then((response) => {
              let data = response.data.map((a) => ({
                ...a,
                locationId: this.designers.find(
                  (designer) => designer.id === a.designerId
                ).locationId,
              }));

              data = data.map((d) => ({
                ...d,
                country: this.manufacturingLocations.find(
                  (l) => l.id === d.locationId
                ).country,
              }));

              const grouped = _.groupBy(
                data,
                (item) =>
                  item.country +
                  "-" +
                  moment(item.startDate)
                    .tz(this.chosenTimezone)
                    .format("YYYY-MM-DD")
              );

              const result = Object.keys(grouped).map((g) => {
                const group = grouped[g];

                return {
                  title: `${group[0].country} (${group.length})`,
                  start: moment(group[0].startDate)
                    .tz(this.chosenTimezone)
                    .format("YYYY-MM-DD"),
                  end: moment(group[0].endDate)
                    .tz(this.chosenTimezone)
                    .format("YYYY-MM-DD"),
                  color: "#008000",
                  allDay: true,
                  extendedProps: {
                    id: g,
                    type: DesignerCalendarItemType.Availability,
                  },
                };
              });

              successCallback(result);
              this.loading = false;
            });
        },
        listSchedule: (fetchInfo, successCallback, failureCallback) => {
          this.loading = true;
          const start = DateTime.fromISO(fetchInfo.startStr).toUTC().toISO();
          const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();
          scheduleService
            .getAvailabilities(start, end, this.chosenProductIds)
            .then((response) => {
              const data = response.data.map((a) => {
                const designer = this.designers.find(
                  (d) => d.id === a.designerId
                );
                const user = this.users.find((d) => d.id === a.designerId);
                const manufacturingLocation = this.manufacturingLocations.find(
                  (d) => d.id === designer.locationId
                );
                const designerProducts = this.products.filter((p) => {
                  const isAssigned =
                    designer.productIds.find((dp) => dp == p.id) !== undefined;

                  return isAssigned;
                });

                return {
                  title: `${user.firstName} ${user.lastName} (${
                    manufacturingLocation.name
                  }) - ${designerProducts.map((p) => p.name).join(", ")}`,
                  start: a.startDate,
                  end: a.endDate,
                  color: "#008000",
                  extendedProps: {
                    type: DesignerCalendarItemType.Availability,
                  },
                };
              });

              successCallback(data);
              this.loading = false;
            });
        },
        sessionsSchedule: (fetchInfo, successCallback, failureCallback) => {
          const start = DateTime.fromISO(fetchInfo.startStr).toUTC().toISO();
          const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();
          scheduleService
            .getDesignSessions(start, end, this.chosenProductIds)
            .then((response) => {
              const events = response.data.map((s) => {
                const product = this.products.find(
                  (p) => p.id === s.productTypeId
                );
                const user = this.users.find((u) => u.id === s.designerId);

                return {
                  title: `${s.requestId}/${product.name} (${user.firstName[0]}${user.lastName[0]})`,
                  start: s.startDate,
                  end: s.endDate,
                  color: "#990000",
                  extendedProps: {
                    id: s.id,
                    type: DesignerCalendarItemType.Session,
                  },
                };
              });

              successCallback(events);
            });
        },
        preReservedDesignSessions: (fetchInfo, successCallback, failureCallback) => {
          const start = DateTime.fromISO(fetchInfo.startStr).toUTC().toISO();
          const end = DateTime.fromISO(fetchInfo.endStr).toUTC().toISO();
          scheduleService
            .getPreReservedDesignSessions(start, end, this.chosenProductIds)
            .then((response) => {
              const events = response.data.map((s) => {
                const product = this.products.find(
                  (p) => p.id === s.productTypeId
                );
                const user = this.users.find((u) => u.id === s.designerId);

                return {
                  title: `${s.requestId}/${product.name} (${user.firstName[0]}${user.lastName[0]})`,
                  start: s.startDate,
                  end: s.endDate,
                  color: "#2D66EB",
                  extendedProps: {
                    id: s.id,
                    type: DesignerCalendarItemType.PreReservation,
                  },
                };
              });

              successCallback(events);
            });
        },
      };
    },
  },
});
</script>
