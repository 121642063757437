<template>
  <div>
    <v-server-table
      style="margin-top: 15px"
      ref="table"
      :columns="columns"
      :options="options"
    >
      <router-link
        :to="'/CombinedOrders/' + props.row.id + currentFilterQuery"
        slot="identifier"
        slot-scope="props"
        >{{ props.row.identifier }}</router-link
      >
      <el-progress
        slot="manufacturingProgress"
        slot-scope="props"
        :percentage="props.row.manufacturingProgress"
        :text-inside="true"
        :stroke-width="18"
      ></el-progress>
    </v-server-table>
  </div>
</template>
<script>
import Vue from "vue";
import { showLoading, hideLoading } from "../../store/mutation-types";
import { mapMutations } from "vuex";
import { HTTP } from "@/services/HttpCommon";
import { Event } from "vue-tables-2";
import qs from "qs";

import _ from "lodash";
import CombinedOrderDefinitionModel from "../../models/CombinedOrderDefinitionModel";

export default Vue.extend({
  mounted() {
    this.initialize();
    this.setIdentifierColumnName();
    Event.$on('removeSorting', this.handleRemoveSorting);
  },
  props: {
    identifierColumnName: {
      type: String,
      required: true,
    },
    combinedOrderDefinition: {
      type: Object,
      default: () => new CombinedOrderDefinitionModel(),
    },
  },
  data() {
    return {
      columns: [
        "identifier",
        "combinedOrderDefinitionName",
        "status",
        "manufacturingProgress",
      ],
      options: {
        headings: {
          identifier: "Identifier",
          combinedOrderDefinitionName: "Definition",
          status: "Status",
          manufacturingProgress: "Manufacturing Progress",
        },
        requestFunction: (data) => {
          data.chosenDefinition = this.combinedOrderDefinition.id;
          let query = _.pick(data, [
            "chosenIdentifiers",
            "chosenDefinition",
            "chosenStatuses",
          ]);

          if (data.orderBy) {
            query.orderBy = data.orderBy;
            query.ascending = data.ascending;
          }

          query = _.omitBy(query, _.isNil);
          this.$router.replace({ query }).catch(() => {
            // do nothing.
          });

          return HTTP.post("CombinedOrder/GetCombinedOrders", data).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
        filterable: false,
        customFilters: ["chosenIdentifiers", "chosenStatuses"],
        sortable: [
          "identifier",
          "combinedOrderDefinitionName",
          "status",
          "manufacturingProgress",
        ],
        initFilters: {
          chosenIdentifiers: this.$route.query.chosenIdentifiers,
          chosenStatuses: this.$route.query.chosenStatuses,
        },
      },
    };
  },
  computed: {
    currentFilterQuery: function () {
      var result = qs.stringify(this.$route.query);
      return result ? "?" + result : "";
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    initialize() {
      Event.$on("vue-tables.loading", () => {
        this.showLoading();
      });

      Event.$on("vue-tables.loaded", () => {
        this.hideLoading();
      });
    },
    setIdentifierColumnName() {
      this.options.headings.identifier =
        this.identifierColumnName || "Identifier";
    },
    handleRemoveSorting() {
      if(this.$refs.table.orderBy.column){
        this.$refs.table.orderBy.column = '';
        this.$refs.table.refresh();
      }
    },
  },
  watch: {
    "$route.query.chosenDefinition": {
      immediate: true,
      handler(newValue) {
        if (this.$refs.table) {
          this.$refs.table.refresh();
        }
        this.setIdentifierColumnName();
      },
    },
  },
  beforeDestroy() {
    Event.$off('removeSorting', this.handleRemoveSorting);
  }
});
</script>
