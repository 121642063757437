<template>
  <div>
    <div
      v-if="model !== null"
      :key="$route.params.id"
      prefix="manufacturingCycleDetails"
    >
      <el-row style="padding-top: 20px">
        <div class="fleft">
          <h1 class="details-header no-margin">MANUFACTURING DETAILS</h1>
          <div>
            <router-link
              :to="planDetailsUrl"
              class="el-button el-button--text"
              type="text"
              style="padding: 6px 0px 0px 0px"
              >Go to plan details</router-link
            >
          </div>
        </div>
        <div v-if="showNavigationButtons" class="fright">
          <router-link
            class="el-button el-button--default el-button--small is-round"
            :class="{ 'is-disabled': model.context.prevRecordId == null }"
            :to="prevManufacturingUrl"
          >
            <i class="el-icon-arrow-up"></i>
          </router-link>
          <span>
            {{ model.context.recordIndexNumber }} of
            {{ model.context.recordsNumber }}
          </span>
          <router-link
            class="el-button el-button--default el-button--small is-round"
            :class="{ 'is-disabled': model.context.nextRecordId == null }"
            :to="nextManufacturingUrl"
          >
            <i class="el-icon-arrow-down"></i>
          </router-link>
        </div>
        <div class="fright" style="padding-right: 10px">
          <router-link
            :to="overviewUrl"
            class="el-button el-button--text"
            style="padding: 10px 0px 0px 0px"
            >Go to overview list</router-link
          >
        </div>
      </el-row>
      <el-row>
        <el-col :span="7">
          <request-information :model="model"></request-information>
        </el-col>
      </el-row>
      <el-row v-for="workOrder in model.workOrderModels" :key="workOrder.id">
        <el-card>
          <div slot="header">
            <h4>{{ workOrder.name }}</h4>
          </div>
          <el-row style="overflow-y: auto">
            <el-col :span="5">
              <work-order-information
                :model="workOrder"
              ></work-order-information>
            </el-col>
            <el-col :span="19">
              <work-order :model="workOrder"></work-order>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
      <el-row>
        <el-tabs>
          <el-tab-pane label="Milestones">
            <milestones-list
              :work-orders="model.workOrderModels"
            ></milestones-list>
          </el-tab-pane>
          <el-tab-pane label="Changes">
            <changes-list :changes="model.changes"></changes-list>
          </el-tab-pane>
          <el-tab-pane label="Comments">
            <comment-list
              :comments="model.comments"
              :dialog-visible.sync="commentDialogVisible"
              :disabled="
                !canManageManufacturingCycle && !canManageFulfilmentPlan
              "
              height="280"
              button-size="mini"
              @add="commentAdd"
              @edit="commentEdit"
              @delete="commentDelete"
            ></comment-list>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <global-notifications></global-notifications>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import { mapActions, mapMutations, mapState } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import GlobalNotifications from "./GlobalNotifications.vue";
import RequestInformation from "./RequestInformation.vue";
import WorkOrderInformation from "./WorkOrderInformation.vue";
import WorkOrder from "./WorkOrder.vue";
import MilestonesList from "./MilestonesList.vue";
import ChangesList from "./ChangesList.vue";
import CommentList from "../shared/CommentList.vue";
import { manufacturingCycleService } from "../../services/manufacturingcycle.service";

export default Vue.extend({
  components: {
    GlobalNotifications,
    RequestInformation,
    WorkOrder,
    WorkOrderInformation,
    MilestonesList,
    ChangesList,
    CommentList,
  },
  mounted() {
    this.$nextTick(() => {
      this.getData();
    });
  },
  computed: {
    ...mapState("manufacturingCycleDetails", ["refreshData"]),
    overviewUrl() {
      const url = "/ManufacturingCycle/List";

      return this.model.context != null
        ? url +
            `?selectedRecordId=${this.model.id}&index=${this.model.context.recordIndexNumber}&recordsPerPage=${this.model.context.displayedRecordsNumber}`
        : url;
    },
    manufacturingCycleId() {
      return this.$route.params.id;
    },
    planDetailsUrl() {
      return `/FulfilmentPlan/Details/${this.model.id}`;
    },
    prevManufacturingUrl() {
      return this.model.context.prevRecordId != null
        ? `/ManufacturingCycle/Details/${this.model.context.prevRecordId}${
            this.useContext ? "?useContext=true" : ""
          }`
        : "#";
    },
    nextManufacturingUrl() {
      return this.model.context.nextRecordId != null
        ? `/ManufacturingCycle/Details/${this.model.context.nextRecordId}${
            this.useContext ? "?useContext=true" : ""
          }`
        : "#";
    },
    showNavigationButtons() {
      return this.model.context !== null;
    },
    canManageManufacturingCycle() {
      return this.model.canManageManufacturingCycles;
    },
    canManageFulfilmentPlan() {
      return this.model.canManageFulfilmentPlan;
    },
    useContext() {
      return this.$route.query.useContext !== undefined;
    },
  },
  watch: {
    refreshData: function (newValue) {
      if (newValue) {
        this.getData();
      }
    },
    "$route.params.id": function () {
      this.getData();
    },
  },
  data() {
    return {
      model: null,
      commentDialogVisible: false,
    };
  },
  methods: {
    ...mapActions("manufacturingCycleDetails", [
      "storeTimestamp",
      "setRefreshData",
    ]),
    ...mapMutations([showLoading, hideLoading]),
    getData() {
      this.showLoading();
      manufacturingCycleService
        .getDetails(this.manufacturingCycleId, this.useContext)
        .then((response) => {
          this.model = response.data;
          this.storeTimestamp(this.model.timestamp);
          this.setRefreshData(false);
          this.hideLoading();
        });
    },
    commentAdd(comment) {
      this.showLoading();
      manufacturingCycleService
        .addComment(this.model.id, comment.text)
        .then((response) => {
          this.commentDialogVisible = false;
          this.hideLoading();
          this.setRefreshData();
        });
    },
    commentEdit(comment) {
      this.showLoading();
      manufacturingCycleService
        .editComment(this.model.id, comment.id, comment.text)
        .then((response) => {
          this.commentDialogVisible = false;
          this.hideLoading();
          this.setRefreshData();
        });
    },
    commentDelete(comment) {
      this.showLoading();
      manufacturingCycleService
        .deleteComment(this.manufacturingCycleId, comment.id)
        .then((response) => {
          this.commentDialogVisible = false;
          this.$message({
            message: "Comment deleted.",
            type: "success",
          });
          this.hideLoading();
          this.setRefreshData();
        });
    },
  },
});
</script>
<style lang="scss" scoped>
::v-deep h4 {
  margin: 0px;
}
</style>
