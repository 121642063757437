<template>
  <div>
    <v-server-table
      name="manufacturingCycleList"
      ref="manufacturingCycleList"
      :columns="columns"
      :options="options"
      @sorted="onSortColumns"
      @loaded="getGlobalNotifications"
    >
      <div slot="requestIdWithManufacturingRevision" slot-scope="props">
        <el-badge
          value="On Hold"
          v-if="props.row.isOnHold"
          type="warning"
          style="top: 4px"
        ></el-badge>
        <router-link
          class="fulfilment-plan-request-link"
          :to="props.row.manufacturingURL"
          >{{ props.row.requestIdWithManufacturingRevision }}</router-link
        >
        <i
          class="el-icon-info table-icon-info"
          v-if="props.row.notificationsAvailable"
        ></i>
        <i
          class="el-icon-warning table-icon-warning"
          v-if="props.row.globalNotificationsAvailable"
        ></i>
        <el-badge
          :value="getSTLBadgeValue(props.row.designApprovalDate)"
          v-if="isCCIFilterActive && props.row.isPrepareSTLDataStepNotCompleted && props.row.designApprovalDate"
          :type="getSTLBadgeType(props.row.designApprovalDate)"
          style="top: 4px"
        ></el-badge>
      </div>
      <div slot="underlyingRequestId" slot-scope="props">
        <router-link
          class="fulfilment-plan-request-link"
          :to="props.row.underlyingFulfilmentPlanUrl"
          >{{ props.row.underlyingRequestId }}</router-link
        >
      </div>
      <div slot="dueDate" slot-scope="props">
        <template v-if="props.row.dueDate !== null">
          {{
            props.row.dueDate
              | momenttz(props.row.manufacturingLocationTimezone)
          }}
        </template>
        <template v-else>-</template>
        {{
          props.row.schedulingStrategy !== null
            ? `(${props.row.schedulingStrategy})`
            : ""
        }}
      </div>
      <div
        v-for="leadTimeColumn in leadTimesColumns"
        :key="leadTimeColumn.jobStepId"
        :slot="leadTimeColumn.name"
        slot-scope="props"
      >
        {{
          props.row.chosenCycleMilestones.find(
            (m) => m.jobStepId == leadTimeColumn.jobStepId
          ).date | momenttz(props.row.manufacturingLocationTimezone)
        }}
      </div>
      <div
        slot="manufacturingProgress"
        slot-scope="props"
        @click="openDetailsModal(props.row.id, props.row.manufacturingURL, null)"
      >
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="props.row.manufacturingProgress"
        ></el-progress>
      </div>
    </v-server-table>
    <el-dialog
      :visible.sync="showDetailsDialog"
      top="5vh"
      title="Manufacturing Cycle Details"
      width="60%"
    > 
      <template v-if="detailsModel !== null" style="padding-top: 0px">
        <label class="details">Plan ID:</label>
        {{ detailsModel.requestIdWithManufacturingRevision }}
        <div
          v-if="detailsModel !== null && detailsModel.context !== null"
          class="fright"
        >
          <el-button
            round
            size="small"
            :disabled="detailsModel.context.prevRecordId === null"
            @click="openDetailsModal(detailsModel.context.prevRecordId, null, detailsModel.contextPrevRecordIsCustomPrototype)"
          >
            <i class="el-icon-arrow-up"></i>
          </el-button>
          <span>
            {{ detailsModel.context.recordIndexNumber }} of
            {{ detailsModel.context.recordsNumber }}
          </span>
          <el-button
            round
            size="small"
            :disabled="detailsModel.context.nextRecordId === null"
            @click="openDetailsModal(detailsModel.context.nextRecordId, null, detailsModel.contextNextRecordIsCustomPrototype)"
          >
            <i class="el-icon-arrow-down"></i>
          </el-button>
        </div>
        <div v-if="!detailsModel.isCustomPrototype">
          <template v-for="workOrder in detailsModel.workOrderModels">
            <manufacturing-job
              v-for="manufacturingJob in workOrder.manufacturingJobs"
              style="margin-top: 30px"
              :key="manufacturingJob.id"
              :model="manufacturingJob"
              :material-number="workOrder.materialNumber"
              :manufacturing-cycle-id="detailsModel.id"
              :timestamp="detailsModel.timestamp"
              @change="manufacturingJobChanged"
            ></manufacturing-job>
          </template>
        </div>
        <div v-if="detailsModel.isCustomPrototype">
          <template v-for="procedure in detailsModel.prototypeWorkProcedureModels">
            <template v-for="manufacturingJob in procedure.prototypeManufacturingJobs">
              <prototype-manufacturing-job
                style="margin-top: 30px;"
                :key="manufacturingJob.id"
                :model="manufacturingJob"
                :material-number="procedure.materialNumber"
                :manufacturing-cycle-id="detailsModel.id"
                :timestamp="detailsModel.timestamp"
                @change="prototypeManufacturingJobChange"
              ></prototype-manufacturing-job>
            </template>
        </template>
        </div>
      </template>
      <template slot="footer">
        <el-button @click="showDetailsDialog = false">Close</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { HTTP } from "../../services/HttpCommon";
import ManufacturingJob from "../manufacturing-cycle-details/ManufacturingJob.vue";
import PrototypeManufacturingJob from "../../components/prototype-manufacturing/PrototypeManufacturingJob.vue"
import { manufacturingCycleService } from "../../services/manufacturingcycle.service";
import { prototypeManufacturingCycleService } from "../../services/prototypemanufacturingcycle.service";
import { manufacturingCycleListService } from "../../services/manufacturingcyclelist.service";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";

import DateTime from "../../mixins/DateTime";
import _ from "lodash";
export default {
  mixins: [DateTime],
  components: {
    ManufacturingJob,
    PrototypeManufacturingJob
  },
  mounted() {
    this.storeInitSortedColumns(false);
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("manufacturingCycleList", [
      "filters",
      "filtersInitialized",
      "updateSortedColumns",
      "availableColumns",
      "availableJobSteps",
      "activeColumns",
      "sortedColumns",
      "initSortedColumns",
      "selectedRecordId",
      "page",
      "availableSortColumnsOrder",
      "filtersLoaded",
      "refresh",
    ]),
    ...mapGetters("manufacturingCycleList", [
      "selectedAdditionalFilters",
      "leadTimesColumns",
    ]),
    renderData() {
      return this.$route.query.storedQueryId === undefined;
    },
    showCommentColumn: function () {
      return this.linkModel.linkedPlans.some(function (linkedPlan) {
        return linkedPlan.comment !== null;
      });
    },
    showWarningMessageColumn: function () {
      return this.linkModel.linkedPlans.some(function (linkedPlan) {
        return linkedPlan.hasWarning;
      });
    },
    columns: function () {
      let defaultColumns = [
        "requestIdWithManufacturingRevision",
        "productSymbol",
        "designStatusDescription",
      ];

      if (this.leadTimesColumns.length === 0) {
        defaultColumns = defaultColumns.concat("dueDate");
      }

      defaultColumns = defaultColumns.concat(
        this.leadTimesColumns.map((c) => c.name)
      );

      defaultColumns = defaultColumns.concat([
        "manufacturingProgress",
        "lastComment",
      ]);

      return defaultColumns;
    },
    sortColumns: function () {
      let defaultColumns = [
        "requestIdWithManufacturingRevision",
        "productSymbol",
        "designStatusDescription",
      ];

      defaultColumns = defaultColumns.concat(
        this.leadTimesColumns.map((c) => c.name)
      );
      defaultColumns = defaultColumns.concat([
        "dueDate",
        "manufacturingProgress",
      ]);
      return defaultColumns;
    },
    isCCIFilterActive: function () {
        return this.filters.storedQueryActiveDisplayName == "Manufacturing CCI";
    }
  },
  watch: {
    refresh: function (newValue) {
      if (newValue) {
        this.$refs.manufacturingCycleList.getData();
      }
    },
    initSortedColumns: function () {
      if (this.initSortedColumns) {
        this.updateColumns();
      }
    },
    sortColumns: function () {
      this.$refs.manufacturingCycleList.options.sortable = this.sortColumns;
    },
    updateSortedColumns: function () {
      if (this.updateSortedColumns) {
        this.updateColumns();
        this.$refs.manufacturingCycleList.getData();
        this.storeUpdateSortedColumns(false);
      }
    },
  },
  data() {
    return {
      initSelectedRecord: true,
      loadingLinkedPlans: false,
      options: {
        filterable: false,
        sendInitialRequest: false,
        headings: {
          requestIdWithManufacturingRevision: "Plan Id",
          productSymbol: "Product Symbol",
          designStatusDescription: "Design Status",
          dueDate: "Out-Of-Manuf.",
          lastComment: "Comment",
          manufacturingProgress: "Manufacturing Progress",
        },
        rowAttributesCallback: (row) => {
          let isSelected = row.id == this.selectedRecordId;
          return isSelected ? { style: "background-color: #E6E6E6" } : {};
        },
        sortable: [
          "requestIdWithManufacturingRevision",
          "productSymbol",
          "designStatusDescription",
          "dueDate",
          "manufacturingProgress",
        ],
        perPage: this.$route.query.recordsPerPage || 10,
        perPageValues: [10, 20, 50, 100],
        initialPage: Math.ceil(this.$route.query.index / this.$route.query.recordsPerPage) || 1,
        preserveState: true,
        saveState: true,
        storage: "local",
        requestFunction: (data) => {
          this.showLoading();
          let filters = this.filters;
          filters.sortColumns = [];
          if (filters.chosenJobSteps) {
            var chosenSteps = [];
            for (let step of filters.chosenJobSteps) {
              chosenSteps.push({ stepId: step[0] });
            }
            filters.selectedWorkflowSteps = chosenSteps;
          }

          filters.sortColumns = this.sortedColumns.map((sortColumn) => {
            const currentSortColumnIndex =
              this.availableSortColumnsOrder.findIndex(
                (x) => x == sortColumn.column
              );

            if (currentSortColumnIndex == -1 && sortColumn.column != null) {
              const milestoneColumn = this.leadTimesColumns.find(
                (c) => c.name.toLowerCase() == sortColumn.column.toLowerCase()
              );

              if (milestoneColumn != null) {
                return {
                  jobStepId: milestoneColumn.jobStepId,
                  sortOrder: sortColumn.ascending ? 1 : 0,
                };
              }
            }
            return {
              sortedColumn: currentSortColumnIndex,
              sortOrder: sortColumn.ascending ? 1 : 0,
            };
          });

          if (this.initSelectedRecord) {
            this.initSelectedRecord = false;
          } else {
            this.storeSelectedRecordId(null);
          }
          return HTTP.post(
            "ManufacturingCycle/GetFilteredManufacturingCycles",
            {
              model: {
                ...filters,
                selectedAdditionalFilters: this.selectedAdditionalFilters,
              },
              limit: data.limit,
              page: data.page,
            }
          ).then((data) => {
            this.refreshed();
            this.hideLoading();
            return data;
          });
        },
        responseAdapter: function (response) {
          return {
            data: response.data.items,
            count: response.data.filteredPlansCount,
          };
        },
      },
      detailsModel: null,
      showDetailsDialog: false,
    };
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapActions("manufacturingCycleList", [
      "storeSortedColumns",
      "storeSelectedRecordId",
      "storeUpdateSortedColumns",
      "storeInitSortedColumns",
      "storeFiltersInitialized",
      "loadChosenFilters",
      "refreshed",
    ]),
    updateColumns() {
      if (this.sortedColumns.length > 0) {
        this.$refs.manufacturingCycleList.orderBy = this.sortedColumns[0];
      } else {
        this.$refs.manufacturingCycleList.orderBy = {
          asceding: true,
          column: false,
        };
      }
      if (this.sortedColumns.length > 1) {
        this.$refs.manufacturingCycleList.userMultiSorting = {};
        this.$refs.manufacturingCycleList.userMultiSorting[
          this.sortedColumns[0].column
        ] = [];
        for (var i = 1; i < this.sortedColumns.length; i++) {
          this.$refs.manufacturingCycleList.userMultiSorting[
            this.sortedColumns[0].column
          ].push(this.sortedColumns[i]);
        }
      }
    },
    onSortColumns() {
      var list = [];
      if (this.$refs.manufacturingCycleList.orderBy) {
        list.push(this.$refs.manufacturingCycleList.orderBy);
      }
      if (
        this.$refs.manufacturingCycleList.userMultiSorting[
          Object.keys(this.$refs.manufacturingCycleList.userMultiSorting)[0]
        ] !== undefined &&
        this.$refs.manufacturingCycleList.userMultiSorting[
          Object.keys(this.$refs.manufacturingCycleList.userMultiSorting)[0]
        ] !== null
      ) {
        for (
          var i = 0;
          i <
          this.$refs.manufacturingCycleList.userMultiSorting[
            Object.keys(this.$refs.manufacturingCycleList.userMultiSorting)[0]
          ].length;
          i++
        ) {
          list.push(
            this.$refs.manufacturingCycleList.userMultiSorting[
              Object.keys(this.$refs.manufacturingCycleList.userMultiSorting)[0]
            ][i]
          );
        }
      }
      this.storeSortedColumns(list);
    },
    openDetailsModal(id, manufacturingURL = "", isCustomPrototype = false) {
      this.showLoading();
      if ((manufacturingURL != null && manufacturingURL.startsWith("/Prototype")) || isCustomPrototype) {
        prototypeManufacturingCycleService.getDetails(id, true).then((response) => {
          this.hideLoading();
          this.detailsModel = response.data;
          this.showDetailsDialog = true;
        });
      }
      else {
        manufacturingCycleService.getDetails(id, true).then((response) => {
          this.hideLoading();
          this.detailsModel = response.data;
          this.showDetailsDialog = true;
        });
      }
    },
    manufacturingJobChanged() {
      this.showLoading();
      manufacturingCycleService
        .getDetails(this.detailsModel.id, true)
        .then((response) => {
          this.detailsModel = response.data;
          this.hideLoading();
        });
    },
    prototypeManufacturingJobChange() {
      this.showLoading();
      prototypeManufacturingCycleService
        .getDetails(this.detailsModel.id, true)
        .then((response) => {
          this.detailsModel = response.data;
          this.hideLoading();
        });
    },
    getGlobalNotifications() {
      if (this.$refs.manufacturingCycleList.count > 0) {
        manufacturingCycleListService
          .getGlobalNotifications(
            this.$refs.manufacturingCycleList.data.map((x) => x.requestId)
          )
          .then((data) => {
            for (var item of data.data.notificationsList) {
              var itemIndex = this.$refs.manufacturingCycleList.data.findIndex(
                (x) => x.requestId === item
              );
              this.$refs.manufacturingCycleList.data[
                itemIndex
              ].globalNotificationsAvailable = true;
            }
          });
      }
    },
    getSTLBadgeType(designApprovalDate) {
      const hoursDiff = this.getHoursDifference(designApprovalDate);
      if (hoursDiff < 48) {
        return "success";
      } else if (hoursDiff >= 48 && hoursDiff <= 75) {
        return "warning";
      } else {
        return "danger";
      }
    },
    getSTLBadgeValue(designApprovalDate) {
      const hoursDiff = this.getHoursDifference(designApprovalDate);
      return `Design approval ${hoursDiff}h ago`;
    },
    getHoursDifference(designApprovalDate) {
      const now = new Date();
      const designDate = new Date(designApprovalDate);
      const diffInMs = now - designDate;
      return Math.trunc(diffInMs / (1000 * 60 * 60));
    }
  },
};
</script>
