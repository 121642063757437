<template>
  <div
    prefix="fulfilmentPlanDetails"
    v-if="configuration !== null"
    :key="$route.params.id"
  >
    <el-row style="padding-top: 20px">
      <div class="fleft">
        <h1 class="details-header no-margin">PLAN DETAILS</h1>
        <div v-if="manufacturingUrl">
          <router-link
            :to="manufacturingUrl"
            class="el-button el-button--text"
            type="text"
            style="padding: 6px 0px 0px 0px"
            >Go to manufacturing details</router-link
          >
        </div>
      </div>
      <div v-if="showNavigationButtons === true" class="fright">
        <router-link
          class="el-button el-button--default el-button--small is-round"
          :class="{ 'is-disabled': model.prevRecordId == null }"
          :to="prevPlanUrl"
        >
          <i class="el-icon-arrow-up"></i>
        </router-link>
        <span>{{ model.recordIndexNumber }} of {{ model.recordsNumber }}</span>
        <router-link
          class="el-button el-button--default el-button--small is-round"
          :class="{ 'is-disabled': model.nextRecordId == null }"
          :to="nextPlanUrl"
        >
          <i class="el-icon-arrow-down"></i>
        </router-link>
      </div>
      <div class="fright" style="padding-right: 10px">
        <router-link
          :to="overviewUrl"
          class="el-button el-button--text"
          style="padding: 10px 0px 0px 0px"
          >Go to overview list</router-link
        >
      </div>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="14">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-row>
              <request-information :configuration="configuration" />
            </el-row>
            <el-row>
              <shipment-information />
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row>
              <plan-data :configuration="configuration" />
            </el-row>
            <el-row v-if="configuration.showManufacturingInformation">
              <manufacturing-information />
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="10">
        <el-row>
          <div class="grid-content">
            <div class="grid-content">
              <el-tabs v-model="tabName" type="border-card">
                <el-tab-pane
                  v-if="!configuration.designSessionOnly"
                  label="Milestones"
                  name="Milestones"
                >
                  <milestones />
                </el-tab-pane>
                <el-tab-pane label="Changes" name="Changes">
                  <changes />
                </el-tab-pane>
                <el-tab-pane label="Comments" name="Comments">
                  <comments />
                </el-tab-pane>
                <el-tab-pane label="Linked Plans" name="Linked Plans">
                  <linked-plans />
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <timeline></timeline>
    </el-row>
  </div>
</template>
<script>
import RequestInformation from "./RequestInformation.vue";
import ManufacturingInformation from "./ManufacturingInformation.vue";
import ShipmentInformation from "./ShipmentInformation.vue";
import PlanData from "./PlanData.vue";
import Milestones from "./Milestones.vue";
import Changes from "./Changes.vue";
import Comments from "./Comments.vue";
import LinkedPlans from "./LinkedPlans.vue";
import Timeline from "./Timeline.vue";
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import DetailsContext from "../../models/fulfilment-plan/DetailsContext";

import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  components: {
    RequestInformation,
    PlanData,
    ManufacturingInformation,
    ShipmentInformation,
    Milestones,
    Changes,
    Comments,
    LinkedPlans,
    Timeline,
  },
  data() {
    return {
      tabName: "Milestones",
      model: new DetailsContext(null),
      showNavigationButtons: false,
      configuration: null,
    };
  },
  mounted() {
    this.loadInitialData();
  },
  watch: {
    "$route.params.id": function (id) {
      this.configuration = null;
      this.model = new DetailsContext(null);
      this.loadInitialData();
    },
  },
  computed: {
    overviewUrl() {
      const url = "/FulfilmentPlan/List";

      return this.model.recordId != null
        ? url +
            `?selectedRecordId=${this.model.recordId}&index=${this.model.recordIndexNumber}&recordsPerPage=${this.model.displayedRecordsNumber}`
        : url;
    },
    nextPlanUrl() {
      return this.model.nextRecordId != null
        ? "/FulfilmentPlan/Details/" +
            this.model.nextRecordId +
            "?useContext=true"
        : "#";
    },
    prevPlanUrl() {
      return this.model.prevRecordId != null
        ? "/FulfilmentPlan/Details/" +
            this.model.prevRecordId +
            "?useContext=true"
        : "#";
    },
    ...mapState("fulfilmentPlanDetails", ["manufacturingUrl"]),
  },
  methods: {
    loadInitialData() {
      const id = this.$route.params.id;

      if (this.$route.query.useContext === "true") {
        this.$nextTick(() => {
          this.getDetailsContext(id);
        });
      }

      fulfilmentPlanDetailsService
        .getRequestConfiguration(id)
        .then((response) => {
          this.configuration = response.data;

          if (this.configuration.designSessionOnly) {
            this.tabName = "Changes";
          }
        });
    },
    getDetailsContext(id) {
      fulfilmentPlanDetailsService.getDetailsContext(id).then(({ data }) => {
        this.model = new DetailsContext(data);

        if (this.model.contextIsValid) {
          this.showNavigationButtons = true;
        }
      });
    },
  },
});
</script>
