import { HTTP } from "./HttpCommon";

class ManufacturingLocationService {
  loadManufacturingLocations() {
    return HTTP.get("ManufacturingLocations/GetRecords");
  }

  loadActiveManufacturingLocations() {
    return HTTP.get("ManufacturingLocations/GetActiveManufacturingLocations");
  }

  addManufacturingLocationMapping(record) {
    return HTTP.post("ManufacturingLocations/Add", record);
  }

  editManufacturingLocationMapping(record) {
    return HTTP.put("ManufacturingLocations/Edit/" + record.id, record);
  }
}

export const manufacturingLocationService = new ManufacturingLocationService();
