<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-page-header @back="goBack" :content="name"></el-page-header>
      </el-col>
      <el-col :span="1" :push="11">
        <el-button id="addButton" v-on:click="handleAddClick">Add</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="3">
        <label class="field-label">Filter by lead time</label>
        <el-select
          v-model="chosenLeadTimes"
          multiple
          clearable
          collapse-tags
          placeholder="None selected"
        >
          <el-option
            v-for="item in availableLeadTimes"
            :label="item"
            :value="item"
            :key="item"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <label class="field-label">Filter by category</label>
        <el-select
          v-model="chosenCategories"
          multiple
          clearable
          collapse-tags
          placeholder="None selected"
        >
          <el-option
            v-for="item in requestTypes"
            :label="item.label"
            :value="item.label"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <template slot="label"> &nbsp; </template>
        <el-button type="primary" size="small" style="position: absolute; bottom: 0" @click="clearFilters"
          >Clear filters</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="filteredData"
      :default-sort="{ prop: 'leadTimeName', order: 'ascending' }"
      style="width: 100%"
    >
      <el-table-column
        sortable
        align="center"
        show-overflow-tooltip
        prop="leadTimeName"
        label="LEAD TIME"
      ></el-table-column>
      <el-table-column
        sortable
        align="center"
        show-overflow-tooltip
        sort-by="productType"
        prop="productType"
        label="Product Type"
      ></el-table-column>
      <el-table-column
        sortable
        align="center"
        show-overflow-tooltip
        prop="requestType"
        label="Category"
      ></el-table-column>
      <el-table-column
        sortable
        align="center"
        show-overflow-tooltip
        sort-by="hours"
        prop="hours"
        label="Days"
      ></el-table-column>
      <el-table-column
        sortable
        align="center"
        show-overflow-tooltip
        sort-by="manufacturingLocation"
        prop="manufacturingLocation"
        label="MANUFACTURING LOCATION"
      ></el-table-column>
      <el-table-column
        sortable
        align="center"
        show-overflow-tooltip
        sort-by="distributionCenter"
        prop="distributionCenter"
        label="DISTRIBUTION CENTER"
      ></el-table-column>
      <el-table-column width="200">
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-on:click="handleEditClick(scope.$index, scope.row)"
            style="margin-left: 10px"
            >Edit</el-button
          >
          <el-button
            v-if="!scope.row.isDefault"
            size="mini"
            type="danger"
            v-on:click="handleDelete(scope.$index, scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      id="addOrEditDialog"
      :visible.sync="addOrEditDialogVisible"
      :title="dialogOperationTitle"
      width="30%"
    >
      <el-form label-width="45%" :model="currentMilestoneLeadTime">
        <el-form-item label="Product Type">
          <el-select
            id="dialogProductTypeSelect"
            :disabled="currentMilestoneLeadTime.isDefault"
            v-model="currentMilestoneLeadTime.productTypeId"
            placeholder="None"
          >
            <el-option
              v-for="item in productTypes"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Category">
          <el-select
            id="dialogRequestTypeSelect"
            :disabled="
              currentMilestoneLeadTime.isDefault ||
              currentMilestoneLeadTime.productTypeId === null
            "
            v-model="currentMilestoneLeadTime.requestTypeId"
            v-on:change="onRequestTypeChange"
            placeholder="None"
          >
            <el-option
              v-for="item in requestTypes"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Lead Time">
          <el-select
            id="dialogMilestoneSelect"
            :disabled="
              currentMilestoneLeadTime.isDefault ||
              currentMilestoneLeadTime.requestTypeId === null
            "
            v-model="currentMilestoneLeadTime.orderItemId"
            v-on:change="onMilestoneChange"
            placeholder="None"
          >
            <el-option
              v-for="item in milestones"
              :label="item.milestones"
              :value="item.orderItemId"
              :key="item.orderItemId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Manufacturing Location">
          <el-select
            id="dialogManufacturingLocationSelect"
            v-model="currentMilestoneLeadTime.manufacturingLocationId"
            :disabled="
              currentMilestoneLeadTime.isDefault ||
              isOutOfManufacturingMilestone
            "
            placeholder="None"
          >
            <el-option
              v-for="item in manufacturingLocations"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Distribution Center">
          <el-select
            id="dialogDistributionCenterSelect"
            v-model="currentMilestoneLeadTime.distributionCenterId"
            :disabled="
              currentMilestoneLeadTime.isDefault ||
              !isOutOfManufacturingMilestone
            "
            placeholder="None"
          >
            <el-option
              v-for="item in distributionCenters"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Days">
          <el-input-number
            id="dialogServiceLevelDaysInput"
            v-model="currentMilestoneLeadTime.hours"
            :min="0"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button id="dialogCancelButton" v-on:click="handleCancelDialog"
          >Cancel</el-button
        >
        <el-button
          id="dialogAddOrEditButton"
          type="primary"
          v-on:click="handleAddOrEdit"
          >{{ dialogOperation }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { milestoneLeadTimeService } from "../../services/milestoneleadtime.service";
import MilestoneLeadTimeGroupItem from "../../models/MilestoneLeadTimeGroupItem";
import { manufacturingLocationService } from "../../services/manufacturinglocation.service";
export default {
  mounted() {
    this.$nextTick(() => {
      let promise1 = milestoneLeadTimeService.loadMilestoneLeadTimeGroupItems(
        this.$route.params.id
      );
      let promise2 = milestoneLeadTimeService.loadDistributionCenters();
      let promise3 = milestoneLeadTimeService.loadProductTypes();
      let promise4 = milestoneLeadTimeService.loadRequestTypes();
      let promise5 = manufacturingLocationService.loadActiveManufacturingLocations();

      promise1.then((response) => {
        this.milestoneLeadTimes = response.data.leadTimes;
        this.name = response.data.leadTimeGroup.name;
        this.defaultProductType = response.data.leadTimeGroup.productTypeId;
      });

      promise2.then((response) => {
        this.distributionCenters = response.data;
      });

      promise3.then((response) => {
        this.productTypes = response.data;
      });

      promise4.then((response) => {
        this.requestTypes = response.data;
      });

      promise5.then((response) => {
        this.manufacturingLocations = response.data;
      });

      Promise.all([promise1, promise2, promise3, promise4, promise5]).then(
        (values) => {
          this.loading = false;
          this.clearCurrent();
        }
      );
    });
  },
  computed: {
    filteredData() {
      return this.milestoneLeadTimes
        .filter(
          (data) => this.filteredLeadTimes.length === 0 || this.filteredLeadTimes.includes(data.leadTimeName)
        )
        .filter(
          (data) => this.filteredCategories.length === 0 || this.filteredCategories.includes(data.requestType)
        );
    },
    dialogOperation() {
      return this.currentMilestoneLeadTime.id !== null ? "Apply" : "Add";
    },
    dialogOperationTitle() {
      return this.currentMilestoneLeadTime.id !== null ? "Edit" : "Add";
    },
    isOutOfManufacturingMilestone() {
      var item = this.milestones.find((item) => {
        return item.orderItemId === this.currentMilestoneLeadTime.orderItemId;
      });

      if (typeof item === "undefined") {
        return false;
      }

      return item.isOutOfManufacturing;
    },
    availableLeadTimes() {
      return _.uniq(this.milestoneLeadTimes.map(row => row.leadTimeName));
    },
    chosenLeadTimes: {
      get: function () {
        return this.filteredLeadTimes;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filteredLeadTimes = [];
        } else {
          this.filteredLeadTimes = newValue;
        }
      },
    },
    chosenCategories: {
      get: function () {
        return this.filteredCategories;
      },
      set: function (newValue) {
        if (!newValue) {
          this.filteredCategories = [];
        } else {
          this.filteredCategories = newValue;
        }
      },
    }
  },
  data() {
    return {
      addOrEditDialogVisible: false,
      isEdit: false,
      loading: true,
      name: "",
      manufacturingLocations: [],
      distributionCenters: [],
      productTypes: [],
      requestTypes: [],
      milestoneLeadTimes: [],
      milestones: [],
      currentMilestoneLeadTime: new MilestoneLeadTimeGroupItem(),
      filteredLeadTimes : [],
      filteredCategories : []
    };
  },
  methods: {
    handleAddClick() {
      this.clearCurrent();
      this.currentMilestoneLeadTime.leadTimeGroupId = this.$route.params.id;
      this.currentMilestoneLeadTime.productTypeId = this.defaultProductType;
      this.isEdit = false;
      this.addOrEditDialogVisible = true;
    },
    handleEditClick(index, row) {
      milestoneLeadTimeService.loadDetails(row.id).then((response) => {
        this.currentMilestoneLeadTime = response.data;

        this.updateMilestones();
      });
    },
    goBack() {
      this.$router.push("/MilestoneLeadTime");
    },
    handleAddOrEdit() {
      if (this.currentMilestoneLeadTime.id !== null) {
        milestoneLeadTimeService
          .updateMilestoneLeadTime(this.currentMilestoneLeadTime)
          .then((response) => {
            let result = response.data;
            if (result.message.length > 0) {
              this.$alert(result.message.join(", "), "ERROR", {
                type: "error",
              });
            } else {
              this.addOrEditDialogVisible = false;
              this.clearCurrent();
              this.loading = true;
              milestoneLeadTimeService
                .loadMilestoneLeadTimeGroupItems(this.$route.params.id)
                .then((response) => {
                  this.milestoneLeadTimes = response.data.leadTimes;
                  this.loading = false;
                });
            }
          });
      } else {
        milestoneLeadTimeService
          .addMilestoneLeadTime(this.currentMilestoneLeadTime)
          .then((response) => {
            let result = response.data;
            if (result.message.length > 0) {
              this.$alert(result.message.join(", "), "ERROR", {
                type: "error",
              });
            } else {
              this.addOrEditDialogVisible = false;
              this.clearCurrent();
              this.loading = true;
              milestoneLeadTimeService
                .loadMilestoneLeadTimeGroupItems(this.$route.params.id)
                .then((response) => {
                  this.milestoneLeadTimes = response.data.leadTimes;
                  this.loading = false;
                });
            }
          });
      }
    },
    handleDelete(index, row) {
      this.$confirm(
        "This will permanently delete Milestone Lead Time. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        milestoneLeadTimeService.deleteMilestoneLeadTime(row.id).then(() => {
          this.loading = true;
          milestoneLeadTimeService
            .loadMilestoneLeadTimeGroupItems(this.$route.params.id)
            .then((response) => {
              this.milestoneLeadTimes = response.data.leadTimes;
              this.loading = false;
            });
        });
      });
    },
    handleCancelDialog() {
      this.clearCurrent();
      this.addOrEditDialogVisible = false;
    },
    onMilestoneChange(newValue) {
      if (
        this.isOutOfManufacturingMilestone &&
        this.currentMilestoneLeadTime.manufacturingLocationId !== null
      ) {
        this.currentMilestoneLeadTime.manufacturingLocationId = null;
      }

      if (
        !this.isOutOfManufacturingMilestone &&
        this.currentMilestoneLeadTime.distributionCenterId !== null
      ) {
        this.currentMilestoneLeadTime.distributionCenterId = null;
      }

      this.updateMilestones();
    },
    onRequestTypeChange(newValue) {
      this.updateMilestones();
    },
    updateMilestones() {
      milestoneLeadTimeService
        .loadMilestones(
          this.currentMilestoneLeadTime.productTypeId,
          this.currentMilestoneLeadTime.requestTypeId
        )
        .then((response) => {
          this.milestones = response.data;
          this.addOrEditDialogVisible = true;
        });
    },
    clearCurrent() {
      this.currentMilestoneLeadTime = new MilestoneLeadTimeGroupItem();
    },
    clearFilters() {
        if (this.chosenLeadTimes.length != 0){
          this.chosenLeadTimes = [];
        }
        if (this.chosenCategories.length != 0){
          this.chosenCategories = [];
        }
    }
  },
};
</script>
