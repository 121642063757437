import { HTTP } from "./HttpCommon";

class ProductTypeService {
  loadProductTypes() {
    return HTTP.get("ProductType/GetRecords");
  }

  loadManualProductTypes() {
    return HTTP.get("ProductType/GetManualCreatedProductTypes");
  }

  addProductType(record) {
    return HTTP.post("ProductType/Add", record);
  }

  editProductType(record) {
    return HTTP.put("ProductType/Edit/" + record.id, record);
  }
}

export const productTypeService = new ProductTypeService();
