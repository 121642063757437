<template>
  <div class="grid-content">
    <el-card style="width: 100%">
      <div slot="header">
        <h4 style="padding-left: 20px; display: inline-block">Plan Data</h4>
      </div>
      <el-form
        ref="form"
        label-width="180px"
        size="mini"
        style="width: 100%"
        v-loading="loadingPlanData || editionLock"
      >
        <el-form-item label="Plan ID" class="form-item-padding">
          <div style="font-size: 12px; padding-left: 15px">
            {{ model.requestId }}
          </div>
        </el-form-item>
        <el-form-item
          v-if="
            model.isProductTypePrioritizable &&
            !(
              configuration.isManufacturingOnly &&
              configuration.isAssociatedWithCustomerOrder
            )
          "
          label="Priority"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <el-checkbox
              v-model="model.priority"
              @change="editPriority"
              :disabled="!canManageFulfilmentPlan"
            ></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item
          v-if="model.isProductManufacturingAtRisk"
          label="Manufacturing At Risk"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <el-checkbox
              v-model="model.manufacturingAtRisk"
              @change="editManufacturingAtRisk"
              :disabled="!canManageFulfilmentPlan || model.priority"
            ></el-checkbox>
          </div>
        </el-form-item>
        <el-form-item
          v-if="
            !configuration.isManufacturingOnly &&
            !configuration.canBeCreatedManually
          "
          label="Design Session Date"
          class="form-item-padding"
        >
          <div v-if="model.hasSession" style="padding-left: 15px">
            <router-link
              :to="designSessionUrl"
              class="el-button el-button--text"
              type="text"
              style="padding: 6px 0px 0px 0px; font-size: 12px"
            >
              {{ model.designSessionDate }}
              <i class="el-icon-time"></i>
            </router-link>
          </div>
          <div
            v-if="!model.hasSession && model.designSessionRequired"
            style="font-size: 12px; padding-left: 15px"
          >
            <editable-date-picker
              v-model="model.designSessionDate"
              @input="editDesignSessionDate(false)"
              :editable="canManageFulfilmentPlan"
            ></editable-date-picker>
          </div>
          <div
            v-if="!model.hasSession && !model.designSessionRequired"
            style="font-size: 12px; padding-left: 15px"
          >
            <editable-date-picker
              v-model="model.designSessionDate"
              @input="editDesignSessionDate(false)"
              placeholder="N/A"
              :editable="canManageFulfilmentPlan"
            ></editable-date-picker>
          </div>
        </el-form-item>
        <el-form-item
          v-if="configuration.designSessionOnly"
          label="Additional Emails"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            {{
              (model.additionalEmails && model.additionalEmails.join(", ")) ||
              "-"
            }}
          </div>
        </el-form-item>
        <el-form-item
          v-if="configuration.designSessionOnly"
          label="Patient Name"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            {{ model.patientName || "-" }}
          </div>
        </el-form-item>
        <el-form-item
          v-if="eRequestDesignOnly"
          label="Modified Lead Times"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            {{ leadTimesModified ? "Modified" : "Not modified" }}
            <el-button
              v-if="canManageFulfilmentPlan"
              style="margin-left: 5px"
              @click="openModificationDialog"
              >Modify</el-button
            >
            <el-button
              v-if="leadTimesModified && canManageFulfilmentPlan"
              style="margin-left: 5px"
              @click="unmodifyLeadTimes"
              >Unmodify</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          v-if="model.trackFileUploaded"
          label="Planning Data Uploaded"
          class="form-item-padding"
        >
          <div style="font-size: 12px; padding-left: 15px">
            <editable-date-picker
              v-model="model.fileUploadedDate"
              @input="editFileUploadedDate"
              placeholder="N/A"
              :editable="canManageFulfilmentPlan"
              :withTime="true"
            ></editable-date-picker>
          </div>
        </el-form-item>
        <el-form-item
          v-if="model.showOverflowDays"
          label="Overflow Days"
          class="form-item-padding"
        >
          <div>
            <editable-number-input
              v-model="model.overflowDays"
              @input="editOverflowDays"
              placeholder="N/A"
            ></editable-number-input>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog
      :visible.sync="modificationDialogVisible"
      title="Lead Time Modification"
      width="500px"
      v-loading="loadingPlanData || editionLock"
    >
      <el-form :model="model" label-width="250px">
        <el-form-item
          v-for="modification in model.leadTimeModifications"
          :label="modification.milestone"
          :key="modification.milestoneId"
        >
          <el-input-number
            v-model="modification.additionalDays"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDefaultModifications">Default</el-button>
        <el-button @click="modificationDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="modifyLeadTimes">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { EditableDatePicker, EditableNumberInput } from "../shared/Shared.vue";
import { OperationResultType } from "../../enums/enums";
import { fulfilmentPlanDetailsService } from "../../services/fulfilmentplandetails.service";
import { mapState, mapActions } from "vuex";
import PlanDataModel from "@/models/fulfilment-plan/PlanDataModel";

export default {
  components: {
    EditableDatePicker,
    EditableNumberInput,
  },
  data() {
    return {
      model: new PlanDataModel(null),
      defaultLeadTimeModifications: [],
      modificationDialogVisible: false,
      loadingPlanData: false,
    };
  },
  computed: {
    ...mapState("fulfilmentPlanDetails", [
      "timestamp",
      "planDataRefresh",
      "canManageFulfilmentPlan",
      "editionLock",
    ]),
    designSessionUrl() {
      return (
        "/Designer/Schedule/" +
        this.model.designerId +
        "?requestId=" +
        this.model.requestId
      );
    },
    leadTimesModified() {
      var notModified =
        this.model.leadTimeModifications.find((x) => x.id !== null) ===
        undefined;

      return !notModified;
    },
    eRequestDesignOnly() {
      return (
        !this.configuration.isManufacturingOnly &&
        !this.configuration.canBeCreatedManually &&
        !this.configuration.designSessionOnly
      );
    }
  },
  watch: {
    planDataRefresh: function () {
      if (this.planDataRefresh) {
        this.getPlanData(this.model.id);
        this.setPlanDataRefresh(false);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPlanData(this.$route.params.id);
    });
  },
  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions("fulfilmentPlanDetails", [
      "setEditionLock",
      "setPlanDataRefresh",
      "setChangesRefresh",
      "setMilestoneRefresh",
      "storeTimestamp",
      "setRequestInformationRefresh",
      "setManufacturingInformationRefresh"
    ]),
    getPlanData(id) {
      this.loadingPlanData = true;
      fulfilmentPlanDetailsService.getPlanData(id).then((response) => {
        const data = response.data;
        this.model = new PlanDataModel(data);

        fulfilmentPlanDetailsService
          .getLeadTimeModifications(this.model.id)
          .then((response) => {
            this.model.leadTimeModifications = response.data.modifications;
            this.defaultLeadTimeModifications = response.data.defaults;
            this.loadingPlanData = false;
          });
      });
    },
    openModificationDialog() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .getLeadTimeModifications(this.model.id)
        .then((response) => {
          this.model.leadTimeModifications = response.data.modifications;
          this.defaultLeadTimeModifications = response.data.defaults;
          this.modificationDialogVisible = true;
          this.setEditionLock(false);
        });
    },
    setDefaultModifications() {
      this.model.leadTimeModifications.forEach((modification) => {
        const defaultSetting = this.defaultLeadTimeModifications.find(
          (defaultModification) =>
            defaultModification.milestoneId === modification.milestoneId
        );

        modification.additionalDays =
          (defaultSetting && defaultSetting.additionalDays) || 0;
      });
    },
    unmodifyLeadTimes() {
      this.model.leadTimeModifications.forEach((element) => {
        element.additionalDays = 0;
      });

      this.modifyLeadTimes();
    },
    modifyLeadTimes() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .updateLeadTimeModifications(
          this.model.id,
          this.model.leadTimeModifications
        )
        .then((response) => {
          this.setChangesRefresh(true);
          this.setMilestoneRefresh(true);
          this.setEditionLock(false);
          this.getPlanData(this.model.id);
          this.modificationDialogVisible = false;
        });
    },
    editPriority() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editPriority(this.model.id, this.model.priority, this.timestamp)
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification("Priority changed succesfully.", "success");
            this.setChangesRefresh(true);
            this.setPlanDataRefresh(true);
            this.setRequestInformationRefresh(true);
            this.setMilestoneRefresh(true);
            this.setManufacturingInformationRefresh(true);
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setPlanDataRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editManufacturingAtRisk() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editManufacturingAtRisk(
          this.model.id,
          this.model.manufacturingAtRisk,
          this.timestamp
        )
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Manufacturing at risk changed succesfully.",
              "success"
            );
            this.setChangesRefresh(true);
            this.setRequestInformationRefresh(true);
            this.setMilestoneRefresh(true);
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setPlanDataRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editDesignSessionDate(ignoreWarnings) {
      this.setEditionLock(true);
      var timeZoneOffset = new Date().getTimezoneOffset();
      fulfilmentPlanDetailsService
        .editDesignSessionDate(
          this.model.id,
          this.model.designSessionDate,
          this.timestamp,
          ignoreWarnings,
          timeZoneOffset
        )
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Design session date changed succesfully.",
              "success"
            );
            this.setChangesRefresh(true);
            this.setMilestoneRefresh(true);
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setPlanDataRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editFileUploadedDate() {
      this.setEditionLock(true);
      var timeZoneOffset = new Date().getTimezoneOffset();
      fulfilmentPlanDetailsService
        .editFileUploadedDate(
          this.model.id,
          this.model.fileUploadedDate,
          this.timestamp,
          timeZoneOffset
        )
        .then(({ data }) => {
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "File uploaded date changed succesfully.",
              "success"
            );
            this.setChangesRefresh(true);
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setPlanDataRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    editOverflowDays() {
      this.setEditionLock(true);
      fulfilmentPlanDetailsService
        .editOverflowDays(this.model.id, this.model.overflowDays)
        .then((response) => {
          const data = response.data;
          if (data.result === OperationResultType.Success) {
            this.storeTimestamp(data.timestamp);
            this.showNotification(
              "Overflow days changed succesfully.",
              "success"
            );
            this.setChangesRefresh(true);
            this.setMilestoneRefresh(true);
          } else if (data.result === OperationResultType.Error) {
            this.showNotification(data.message, "error");
            this.setPlanDataRefresh(true);
          }
          this.setEditionLock(false);
        });
    },
    openConfirmPopup(message, confirmAction) {
      this.$confirm(message, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          confirmAction();
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Action aborted.",
          });
        });
    },
    showNotification(message, type) {
      this.$message({
        message: message,
        type: type,
        duration: 4000,
      });
    },
  },
};
</script>
