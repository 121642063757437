
















































































import Vue from "vue";
import { mapMutations } from "vuex";
import axios from "axios";
import _ from "lodash";

import qs from "qs";

import { combinedOrderService } from "../../services/combinedorder.service";
import { HTTP } from "@/services/HttpCommon";
import CombinedOrderInformation from "./CombinedOrderInformation.vue";
import CombinedOrderRequestIds from "./CombinedOrderRequestIds.vue";
import CombinedOrderManufacturingJob from "./CombinedOrderManufacturingJob.vue";
import CommentList from "@/components/shared/CommentList.vue";

import ManufacturingJobModel from "../../models/ManufacturingJobModel";
import CombinedManufacturingJobModel from "../../models/CombinedManufacturingJobModel";
import CombinedOrderInformationModel from "../../models/CombinedOrderInformationModel";

import CommentModel from "@/models/CommentModel";
import CommentFormModel from "@/models/CommentFormModel";

import { showLoading, hideLoading } from "../../store/mutation-types";
import { OperationResultType } from "../../enums/enums";
import ChangeDataResponseModel from "../../models/ChangeDataResponseModel";

export default Vue.extend({
  components: {
    CommentList,
    CombinedOrderRequestIds,
    CombinedOrderInformation,
    CombinedOrderManufacturingJob,
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
  data() {
    return {
      comments: [],
      commentDialogVisible: false,
      manufacturingJob: new ManufacturingJobModel(),
      combinedManufacturingJobs: [],
      information: new CombinedOrderInformationModel(),
      combinedOrdersCount: null,
      currentItem: null,
      currentItemIndex: null,
      nextCombinedOrderId: null,
      nextCombinedOrderIdentifier: null,
      previousCombinedOrderId: null,
      previousCombinedOrderIdentifier: null
    };
  },
  computed: {
    queryString: function () {
      var result = qs.stringify(this.$route.query);

      return result ? "?" + result : "";
    },
    canAddPlans: function () {
      return (
        this.manufacturingJob.currentSequenceNumber <= 1 &&
        (this.manufacturingJob.canStart ||
          (!this.manufacturingJob.canStart && !this.manufacturingJob.canFinish))
      );
    },
    previousCombinedOrderUrl() {
      return this.previousCombinedOrder !== null
        ? "/CombinedOrders/" + this.previousCombinedOrderId + this.queryString
        : "";
    },
    previousCombinedOrderText() {
      return this.previousCombinedOrder !== null
        ? "Previous Combined Order: " + this.previousCombinedOrderIdentifier
        : "Not available";
    },
    nextCombinedOrderUrl() {
      return this.nextCombinedOrderId !== null
        ? "/CombinedOrders/" + this.nextCombinedOrderId + this.queryString
        : "";
    },
    nextCombinedOrderText() {
      return this.nextCombinedOrderId !== null
        ? "Next Combined Order: " + this.nextCombinedOrderIdentifier
        : "Not available";
    },
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    ...mapMutations("combinedOrder", ["storeCombinedOrderId"]),
    loadData() {
      this.showLoading();
      this.storeCombinedOrderId(this.$route.params.id);
      axios
        .all([this.getWorkOrder(), this.getCombinedManufacturingJobs()])
        .then(() => {
          this.hideLoading();
        });
      var body = qs.parse(this.$route.query);
      body.targetId = this.$route.params.id;
      HTTP.post(
        "CombinedOrder/GetCombinedOrderForDetails",
        body
      ).then((data) => {
        console.log(this.$store.state.combinedOrder.combinedOrderId);
        const {
          targetCombinedOrder,
          targetIndex,
          count,
          previousCombinedOrderId,
          previousCombinedOrderIdentifier,
          nextCombinedOrderId,
          nextCombinedOrderIdentifier
        } = data.data;

        this.currentItem = targetCombinedOrder;
        this.currentItemIndex = targetIndex;
        this.combinedOrdersCount = count;
        this.previousCombinedOrderId = previousCombinedOrderId;
        this.previousCombinedOrderIdentifier = previousCombinedOrderIdentifier;
        this.nextCombinedOrderId = nextCombinedOrderId;
        this.nextCombinedOrderIdentifier = nextCombinedOrderIdentifier;
      });
    },
    onRequestIdChanged(id) {
      this.showLoading();

      Promise.all([
        this.getCombinedManufacturingJobs(),
        this.getWorkOrder(),
      ]).then(() => {
        this.hideLoading();
      });
    },
    reloadWorkOrder() {
      this.showLoading();
      this.getWorkOrder().then(() => {
        this.hideLoading();
      });
    },
    getWorkOrder() {
      var promise = combinedOrderService.getWorkOrder(
        this.$store.state.combinedOrder.combinedOrderId
      );
      promise.then((data) => {
        this.manufacturingJob = new ManufacturingJobModel(
          data.data.manufacturingJob
        );
        this.information = new CombinedOrderInformationModel(data.data);
      });

      return promise;
    },
    getCombinedManufacturingJobs() {
      var promise = combinedOrderService.getCombinedManufacturingJobs(
        this.$store.state.combinedOrder.combinedOrderId
      );
      promise.then((data) => {
        this.combinedManufacturingJobs = data.data.map((item) => {
          return new CombinedManufacturingJobModel(item);
        });
      });

      return promise;
    },
    addComment(comment: CommentFormModel) {
      this.commentDialogVisible = false;
      combinedOrderService
        .addComment(
          this.$store.state.combinedOrder.combinedOrderId,
          comment.text
        )
        .then((response) => {
          this.reloadWorkOrder();
          this.showCommentResponseMessage(response.data);
        });
    },
    editComment(comment: CommentModel) {
      this.commentDialogVisible = false;
      combinedOrderService.editComment(comment).then((response) => {
        this.reloadWorkOrder();
        this.showCommentResponseMessage(response.data);
      });
    },
    deleteComment(comment: CommentModel) {
      combinedOrderService.deleteComment(comment).then((response) => {
        this.reloadWorkOrder();
        this.showCommentResponseMessage(response.data);
      });
    },
    showCommentResponseMessage(data: ChangeDataResponseModel) {
      this.$message({
        message: data.message,
        type: data.result == OperationResultType.Success ? "success" : "error",
        duration: 4000,
      });
    },
  },
  watch: {
    "$route.params.id": function (id) {
      this.loadData();
    },
  },
});
