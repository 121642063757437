import moment from "moment";
import "moment-timezone";
import MilestoneDataModel from "./MilestoneDataModel";

export default class MilestoneListModel {
  fulfilmentPlanId: number = null;
  milestones: MilestoneDataModel[] = [];
  showTimeline = false;
  lockedMilestones = false;

  constructor(data) {
    if (data !== null) {
      this.fulfilmentPlanId = data.model.fulfilmentPlanId;
      this.lockedMilestones = data.model.lockedMilestones;
      this.showTimeline = data.model.showTimeline;
      for (const milestone of data.model.milestones) {
        const newMilestone = new MilestoneDataModel(milestone);
        this.milestones.push(newMilestone);
      }
    }
  }

  refresh(data) {
    if (data !== null) {
      this.fulfilmentPlanId = data.model.fulfilmentPlanId;
      this.lockedMilestones = data.model.lockedMilestones;
      this.showTimeline = data.model.showTimeline;
      for (const milestone of data.model.milestones) {
        this.milestones.find((x) => x.id === milestone.id).dueDate =
          milestone.plannedDate
            ? moment
                .tz(milestone.plannedDate, milestone.timezone)
                .format("YYYY-MM-DD")
            : null;
        this.milestones.find((x) => x.id === milestone.id).actualDate =
          milestone.actualDate
            ? moment(milestone.actualDate).format("YYYY-MM-DD")
            : null;
        this.milestones.find((x) => x.id === milestone.id).name = milestone.name;
      }
    }
  }
}
