<template>
  <div :key="$route.params.id">
    <changes-list-filters
      ref="filters"
      @change="filterChanged"
    ></changes-list-filters>
    <v-server-table
      v-if="filtersInitialized"
      style="margin-top: 15px"
      ref="table"
      :columns="columns"
      :options="options"
    >
      <router-link
        :to="'/FulfilmentPlan/Details/' + props.row.fulfilmentPlanId"
        slot="requestId"
        slot-scope="props"
        >{{ props.row.requestId }}</router-link
      >
      <div slot="date" slot-scope="props">
        {{ asLocalDate(props.row.date) }}
      </div>
    </v-server-table>
  </div>
</template>

<script>
import Vue from "vue";
import { Event } from "vue-tables-2";
import { DateTime, Settings } from "luxon";
import { mapMutations } from "vuex";
import { showLoading, hideLoading } from "../../store/mutation-types";
import ChangesListFilters from "./ChangesListFilters.vue";
import { changesService } from "../../services/changes.service";

import { ChangesChosenFiltersModel } from "../../models/ChangesChosenFiltersModel";

export default Vue.extend({
  components: {
    ChangesListFilters,
  },
  mounted() {
    Settings.defaultLocale = "en-GB";

    Event.$on("vue-tables.loading", () => {
      this.showLoading();
    });
    
    Event.$on("vue-tables.loaded", () => {
      this.updateTableColumns();
      this.hideLoading();
    });
  },
  data() {
    return {
      filtersInitialized: false,
      columns: [],
      options: {
        headings: {
          requestId: "Plan ID",
          productType: "Product Type",
          date: "Date",
          changeType: "Change Type",
          message: "Message",
          distributionCenter: "Distribution Center",
          userSymbol: "User",
          poNumber: "PO Number"
        },
        requestFunction: (data) => {
          return changesService
            .getChangesItems({
              ...this.$store.state.changes.chosenFilters,
              ...data,
            })
            .catch(
              function (e) {
                this.dispatch("error", e);
              }.bind(this)
            );
        },
        responseAdapter: function (response) {
          return {
            data: response.data.data,
            count: response.data.count,
          };
        },
        filterable: false,
        sortable: [
          "requestId",
          "productType",
          "date",
          "changeType",
          "userSymbol",
        ],
        initFilters: new ChangesChosenFiltersModel(null),
      },
    };
  },
  methods: {
    ...mapMutations([showLoading, hideLoading]),
    filterChanged() {
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }

      this.filtersInitialized = true;
    },
    asLocalDate(date) {
      if (date) {
        return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
      } else {
        return "-";
      }
    },
    updateTableColumns() {
      var columns = [
        "requestId",
        "productType",
        "date",
        "changeType",
        "message",
        "distributionCenter",
        "userSymbol"
      ]
      var changeTypeLabel = this.$store.state.changes.chosenFilters.changeTypeFilter.changeTypeLabel;
      if (changeTypeLabel.toUpperCase().includes("PO NUMBER")) {
        columns.push("poNumber");
      }

      this.columns = columns;
    }
  },
});
</script>
