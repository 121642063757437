<template>
  <el-dialog
    width="25%"
    title="Add new plan"
    :visible.sync="visible"
    :append-to-body="true"
    :before-close="closeForm"
  >
    <el-form :model="plan" :rules="rules" ref="commentForm" label-width="160px">
      <el-form-item label="Order Number" prop="orderNumber">
        <el-input v-model="plan.orderNumber"></el-input>
      </el-form-item>
      <el-form-item label="Category" prop="requestTypeId">
        <el-select filterable style="width: 100%" v-model="plan.requestTypeId">
          <el-option
            v-for="item in availableRequestTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="plan.requestTypeId === requestCategory.ReOrder"
        label="Underlying Request"
        prop="underlyingRequestId"
      >
        <el-autocomplete
          v-model="plan.underlyingRequestId"
          :fetch-suggestions="getPossibleUnderlyingRequestIds"
          placeholder="None selected"
          clearable
          style="width: 100%"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item
        v-if="plan.requestTypeId === requestCategory.Prototype"
        label="Product Type"
        prop="productType"
      >
        <el-select
          filterable
          style="width: 100%"
          @change="productTypeSelected"
          v-model="plan.productType"
        >
          <el-option
            v-for="item in availableProductTypes"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="plan.requestTypeId === requestCategory.Prototype"
        label="Product Number"
        prop="productNumber"
      >
        <el-select filterable style="width: 100%" v-model="plan.productNumber">
          <el-option
            v-for="item in availableProducts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Manuf. Location" prop="manufacturingLocationId">
        <el-select
          filterable
          style="width: 100%"
          v-model="plan.manufacturingLocationId"
        >
          <el-option
            v-for="item in availableManufacturingLocations"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="plan.requestTypeId === requestCategory.ReOrder"
        label="Quantity"
        prop="quantity"
      >
        <el-input type="number" v-model="plan.quantity"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeForm">Cancel</el-button>
      <el-button @click="saveNewPlan" type="primary">Add</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import { fulfilmentPlanListService } from "../../services/fulfilmentplanlist.service";
import { productTypeService } from "@/services/producttype.service";
import { RequestCategory } from "../../enums/enums";
import { mapState } from "vuex";

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("fulfilmentPlanList", [
      "availableRequestTypes",
      "availableManufacturingLocations",
    ]),
  },
  created() {
    productTypeService.loadManualProductTypes().then((response) => {
      this.availableProductTypes = response.data.records;
    });
  },
  data() {
    return {
      requestCategory: RequestCategory,
      rules: {
        orderNumber: [{ required: true, message: "Order Number is required" }],
        requestTypeId: [{ required: true, message: "Category is required" }],
        productType: [{ required: true, message: "Produce Type is required" }],
        manufacturingLocationId: [
          { required: true, message: "Manufacturing Location is required" },
        ],
        underlyingRequestId: [
          { required: true, message: "Underlying Request is required" },
        ],
      },
      availableProductTypes: [],
      availableRequests: [],
      availableProducts: [],
      renderComponent: true,
      loading: false,
      plan: {
        orderNumber: null,
        requestTypeId: null,
        productType: null,
        productNumber: null,
        underlyingRequestId: null,
        manufacturingLocationId: null,
        quantity: null,
      },
    };
  },
  methods: {
    closeForm() {
      this.$refs.commentForm.resetFields();
      this.$emit("update:visible", false);
      this.item = {
        identifier: null,
        orderNumber: null,
        combinedOrderDefinitionId: null,
      };
    },
    getPossibleUnderlyingRequestIds(queryString, callback) {
      fulfilmentPlanListService
        .getPossibleUnderlyingRequestIds(queryString)
        .then((response) => {
          const result = response.data;
          callback(
            result.data.map(function (x) {
              return { value: x };
            })
          );
        });
    },
    productTypeSelected(productTypeId) {
      if (productTypeId != null) {
        const productTypeManufacturingLocationId =
          this.availableProductTypes.find(
            (pt) => pt.id == productTypeId
          ).defaultManufacturingLocationId;
        this.plan.manufacturingLocationId = productTypeManufacturingLocationId;
      }

      this.availableProducts = [];
      if (this.plan.productNumber) {
        this.plan.productNumber = "";
      }
      fulfilmentPlanListService
        .getProductsForFulfilmentPlan(productTypeId)
        .then((response) => {
          const data = response.data;
          this.availableProducts = data;
        });
    },
    saveNewPlan() {
      this.$refs.commentForm.validate((valid) => {
        if (valid) {
          fulfilmentPlanListService.saveNewPlan(this.plan).then((response) => {
            const data = response.data;
            if (data.success) {
              window.location.href =
                "/CRT/FulfilmentPlan/Details/" + data.fulfilmentPlanId;
            } else {
              this.$message({
                type: "error",
                message: data.message.toString(),
              });
            }
          });
        }
        return false;
      });
    },
  },
});
</script>
