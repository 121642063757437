export default class PlanDataModel {
  recordId: number;
  recordIndexNumber: number;
  recordsNumber: number;
  nextRecordId: number;
  prevRecordId: number;
  contextIsValid: boolean;
  displayedRecordsNumber: number;

  constructor(data) {
    if (data !== null && data.model != null) {
      this.recordIndexNumber = data.model.recordIndexNumber;
      this.recordsNumber = data.model.recordsNumber;
      this.recordId = data.model.recordId;
      this.nextRecordId = data.model.nextRecordId;
      this.prevRecordId = data.model.prevRecordId;
      this.contextIsValid = true;
      this.displayedRecordsNumber = data.model.displayedRecordsNumber;
    }
  }
}
