<template>
  <div id="navMenu">
    <el-container>
      <div v-if="menuMode === 'horizontal'" style="margin-top: 80px"></div>
      <el-header
        v-if="menuMode === 'horizontal'"
        style="padding: 0px; z-index: 100; position: fixed; width: 100%"
      >
        <el-menu
          :default-active="activeIndex"
          :mode="menuMode"
          :collapse="isCollapsed"
          background-color="#5591be"
          text-color="#fff"
          active-text-color="#fff"
        >
          <el-menu-item index="0">
            <a href="/" class="navbar-link">{{ navbarTitle }}</a>
          </el-menu-item>
          <el-submenu
            v-for="tab in menuItems.filter((i) => i.privilege)"
            :key="tab.index"
            :index="tab.index"
          >
            <template slot="title">
              <font-awesome-icon
                :icon="tab.tabIcon"
                style="font-size: 16px; text-align: center; width: 24px"
                aria-hidden="true"
              />
              <span slot="title">{{ tab.tabLabel }}</span>
            </template>
            <div v-for="item in tab.subItems" :key="item.url">
              <template
                v-if="item.privilege && item.type === menuItemType.Item"
              >
                <router-link
                  v-if="canUseRouter(item.url)"
                  :key="item.index"
                  :to="clearPrefix(item.url)"
                  class="navbar-link"
                >
                  <el-menu-item :index="item.index">
                    {{ item.label }}
                  </el-menu-item>
                </router-link>
                <router-link
                  v-else
                  :key="item.index"
                  :to="item.url"
                  class="navbar-link"
                >
                  <el-menu-item :index="item.index">
                    {{ item.label }}
                  </el-menu-item>
                </router-link>
              </template>
              <el-submenu
                :index="item.index"
                v-if="item.privilege && item.type === menuItemType.SubItem"
              >
                <template slot="title">{{ item.label }}</template>
                <template
                  v-for="subitem in item.subItems.filter((i) => i.privilege)"
                >
                  <router-link
                    v-if="canUseRouter(subitem.url)"
                    :key="subitem.index"
                    :to="clearPrefix(subitem.url)"
                    class="navbar-link"
                  >
                    <el-menu-item :index="subitem.index">
                      <font-awesome-icon
                        v-if="subitem.icon"
                        :icon="subitem.icon"
                        style="font-size: 16px; width: 24px"
                        aria-hidden="true"
                      />
                      {{ subitem.label }}
                    </el-menu-item>
                  </router-link>
                  <router-link
                    v-else
                    :key="subitem.index"
                    :to="subitem.url"
                    class="navbar-link"
                  >
                    <el-menu-item :index="subitem.index">
                      <font-awesome-icon
                        v-if="subitem.icon"
                        :icon="subitem.icon"
                        style="font-size: 16px; width: 24px"
                        aria-hidden="true"
                      />
                      {{ subitem.label }}
                    </el-menu-item>
                  </router-link>
                </template>
              </el-submenu>
            </div>
          </el-submenu>
          <el-submenu index="5" style="float: right">
            <template slot="title">
              <font-awesome-icon
                icon="cogs"
                style="font-size: 16px; text-align: center; width: 24px"
                aria-hidden="true"
              />
            </template>
            <div v-for="item in configurationMenuItems" :key="item.url">
              <template
                v-if="item.privilege && item.type === menuItemType.Item"
              >
                <router-link
                  v-if="canUseRouter(item.url)"
                  :key="item.index"
                  :to="clearPrefix(item.url)"
                  class="navbar-link"
                >
                  <el-menu-item :index="item.index">
                    {{ item.label }}
                  </el-menu-item>
                </router-link>
                <router-link
                  v-else
                  :key="item.index"
                  :to="item.url"
                  class="navbar-link"
                >
                  <el-menu-item :index="item.index">
                    {{ item.label }}
                  </el-menu-item>
                </router-link>
              </template>

              <el-submenu
                :index="item.index"
                v-if="item.privilege && item.type === menuItemType.SubItem"
              >
                <template slot="title">{{ item.label }}</template>
                <template
                  v-for="subitem in item.subItems.filter((i) => i.privilege)"
                >
                  <router-link
                    v-if="canUseRouter(subitem.url)"
                    :key="subitem.index"
                    :to="clearPrefix(subitem.url)"
                    class="navbar-link"
                  >
                    <el-menu-item :index="subitem.index">
                      {{ subitem.label }}
                    </el-menu-item>
                  </router-link>
                  <router-link
                    v-else
                    :key="subitem.index"
                    :to="subitem.url"
                    class="navbar-link"
                  >
                    <el-menu-item :index="subitem.index">
                      {{ subitem.label }}
                    </el-menu-item>
                  </router-link>
                </template>
              </el-submenu>
            </div>
            <el-menu-item @click="aboutModalVisible = true">ABOUT</el-menu-item>
          </el-submenu>
          <el-submenu index="6" style="float: right">
            <template slot="title">
              <font-awesome-icon
                icon="user-cog"
                style="font-size: 16px; text-align: center; width: 24px"
                aria-hidden="true"
              />
            </template>
            <el-menu-item>
              <div style="text-align: center">
                <font-awesome-icon
                  icon="user"
                  style="font-size: 12px; vertical-align: central; width: 16px"
                  aria-hidden="true"
                />
                {{ oidcUser && oidcUser.name.toUpperCase() }}
              </div>
            </el-menu-item>
            <el-menu-item index="6-1">
              MENU MODE
              <el-radio-group v-model="menuModeSelect" size="mini">
                <el-radio-button label="AUTO"></el-radio-button>
                <el-radio-button label="HORIZONTAL"></el-radio-button>
                <el-radio-button label="VERTICAL"></el-radio-button>
                <el-radio-button label="COLLAPSE"></el-radio-button>
              </el-radio-group>
            </el-menu-item>
            <el-menu-item
              index="6-2"
              @click="changePasswordDialogVisible = true"
              >CHANGE PASSWORD</el-menu-item
            >
            <div v-for="item in userMenuItems" :key="item.url">
              <template
                v-if="item.privilege && item.type === menuItemType.Item"
              >
                <router-link
                  v-if="canUseRouter(item.url)"
                  :key="item.index"
                  :to="clearPrefix(item.url)"
                  class="navbar-link"
                >
                  <el-menu-item :index="item.index">
                    {{ item.label }}
                  </el-menu-item>
                </router-link>
                <router-link
                  v-else
                  :key="item.index"
                  :to="item.url"
                  class="navbar-link"
                >
                  <el-menu-item :index="item.index">
                    {{ item.label }}
                  </el-menu-item>
                </router-link>
              </template>
              <el-submenu
                :index="item.index"
                v-if="item.privilege && item.type === menuItemType.SubItem"
              >
                <template slot="title">{{ item.label }}</template>
                <template
                  v-for="subitem in item.subItems.filter((i) => i.privilege)"
                >
                  <router-link
                    v-if="canUseRouter(subitem.url)"
                    :key="subitem.index"
                    :to="clearPrefix(subitem.url)"
                    class="navbar-link"
                  >
                    <el-menu-item :index="subitem.index">
                      {{ subitem.label }}
                    </el-menu-item>
                  </router-link>
                  <router-link
                    v-else
                    :key="subitem.index"
                    :to="subitem.url"
                    class="navbar-link"
                  >
                    <el-menu-item :index="subitem.index">
                      {{ subitem.label }}
                    </el-menu-item>
                  </router-link>
                </template>
              </el-submenu>
            </div>
            <a class="navbar-link" @click="signOut">
              <el-menu-item index="6-15">LOG OUT</el-menu-item>
            </a>
          </el-submenu>
          <el-submenu
            index="7"
            style="float: right"
            popper-class="notifications-menu"
            @mouseenter.native="getNotifications"
            v-if="hasAnyCrtPermission"
          >
            <template slot="title">
              <font-awesome-icon
                icon="bell"
                style="font-size: 16px; text-align: center; width: 24px"
                aria-hidden="true"
              ></font-awesome-icon>
              <el-badge
                v-if="numberOfNotifications > 0"
                class="item"
                :value="numberOfNotifications"
                style="left: -8px"
                type="primary"
              />
            </template>
            <el-table
              :data="notificationsList"
              height="250"
              size="mini"
              :empty-text.sync="emptyNotificationsText"
            >
              <el-table-column prop="requestId" label="Plan id" width="120">
                <template slot-scope="scope">
                  <span>
                    <router-link
                      :to="
                        scope.row.fulfilmentPlanId
                          ? '/FulfilmentPlan/Details/' +
                            scope.row.fulfilmentPlanId
                          : '/ManufacturingCycle/Details/' +
                            scope.row.manufacturingCycleId
                      "
                      >{{ scope.row.requestId }}</router-link
                    >
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Date" width="120">
                <template slot-scope="scope">
                  <i class="el-icon-date"></i>
                  <span style="margin-left: 10px">
                    {{ scope.row.time | moment }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="notificationMessage"
                label="Message"
                width="500"
              ></el-table-column>
              <el-table-column label="Message" width="60">
                <template slot="header">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="acknowledgeNotifications"
                  >
                    <font-awesome-icon
                      icon="check-double"
                      style="font-size: 10px"
                      aria-hidden="true"
                    />
                  </el-button>
                </template>
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="acknowledgeNotification(scope.row.notyficationId)"
                  >
                    <font-awesome-icon
                      icon="check"
                      style="font-size: 10px"
                      aria-hidden="true"
                    />
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-submenu>
          <el-menu-item style="float: right" index="8">
            <el-input
              placeholder="Search ..."
              ref="searchInput"
              v-model="search"
              @keyup.enter.native="searchPhrase"
              size="mini"
            >
              <template slot="suffix">
                <i
                  v-if="isSearching"
                  class="el-input__icon el-icon-loading"
                ></i>
                <i
                  v-else
                  class="el-input__icon el-icon-search"
                  @click="searchPhrase"
                ></i>
              </template>
            </el-input>
          </el-menu-item>
        </el-menu>
      </el-header>
      <el-container>
        <el-aside
          width
          style="height: 100vh"
          class="aside-container"
          v-if="menuMode === 'vertical'"
        >
          <div id="asideMenu">
            <el-menu
              :v-loading="true"
              :default-active="activeIndex"
              style="height: -webkit-fill-available; width: fit-content"
              :collapse-transition="false"
              :class="{ menuMaxWidth: !isCollapsed }"
              :mode="menuMode"
              :collapse="isCollapsed"
              background-color="#5591be"
              text-color="#fff"
              active-text-color="#fff"
            >
              <el-menu-item index="0">
                <a href="/" class="navbar-link">CRT</a>
              </el-menu-item>
              <el-submenu
                v-for="tab in menuItems.filter((i) => i.privilege)"
                :key="tab.index"
                :index="tab.index"
              >
                <template slot="title">
                  <font-awesome-icon
                    :icon="tab.tabIcon"
                    style="font-size: 16px; text-align: center; width: 24px"
                    aria-hidden="true"
                  />
                  <span slot="title">{{ tab.tabLabel }}</span>
                </template>
                <div v-for="item in tab.subItems" :key="item.url">
                  <template
                    v-if="item.privilege && item.type === menuItemType.Item"
                  >
                    <router-link
                      v-if="canUseRouter(item.url)"
                      :key="item.index"
                      :to="clearPrefix(item.url)"
                      class="navbar-link"
                    >
                      <el-menu-item :index="item.index">
                        {{ item.label }}
                      </el-menu-item>
                    </router-link>
                    <router-link
                      v-else
                      :key="item.index"
                      :to="item.url"
                      class="navbar-link"
                    >
                      <el-menu-item :index="item.index">
                        {{ item.label }}
                      </el-menu-item>
                    </router-link>
                  </template>
                  <el-submenu
                    :index="item.index"
                    v-if="item.privilege && item.type === menuItemType.SubItem"
                  >
                    <template slot="title">{{ item.label }}</template>
                    <template
                      v-for="subitem in item.subItems.filter(
                        (i) => i.privilege
                      )"
                    >
                      <router-link
                        v-if="canUseRouter(subitem.url)"
                        :key="subitem.index"
                        :to="clearPrefix(subitem.url)"
                        class="navbar-link"
                      >
                        <el-menu-item :index="subitem.index">
                          <font-awesome-icon
                            v-if="subitem.icon"
                            :icon="subitem.icon"
                            style="font-size: 16px; width: 24px"
                            aria-hidden="true"
                          />
                          {{ subitem.label }}
                        </el-menu-item>
                      </router-link>
                      <router-link
                        v-else
                        :key="subitem.index"
                        :to="subitem.url"
                        class="navbar-link"
                      >
                        <el-menu-item :index="subitem.index">
                          <font-awesome-icon
                            v-if="subitem.icon"
                            :icon="subitem.icon"
                            style="font-size: 16px; width: 24px"
                            aria-hidden="true"
                          />
                          {{ subitem.label }}
                        </el-menu-item>
                      </router-link>
                    </template>
                  </el-submenu>
                </div>
              </el-submenu>
              <el-menu-item index="8" v-if="!isCollapsed">
                <el-input
                  placeholder="Search ..."
                  v-model="search"
                  ref="searchInput"
                  @keyup.enter.native="searchPhrase"
                  size="mini"
                >
                  <template slot="suffix">
                    <i
                      v-if="isSearching"
                      class="el-input__icon el-icon-loading"
                    ></i>
                    <i
                      v-else
                      class="el-input__icon el-icon-search"
                      @click="searchPhrase"
                    ></i>
                  </template>
                </el-input>
              </el-menu-item>
              <el-submenu index="8" v-if="isCollapsed">
                <template slot="title">
                  <font-awesome-icon
                    icon="search"
                    style="font-size: 16px; text-align: center; width: 24px"
                    aria-hidden="true"
                  />
                  <span slot="title">Search</span>
                </template>
                <div
                  class="el-menu-item"
                  style="padding-left: 20px; color: rgb(255, 255, 255)"
                >
                  <el-input
                    placeholder="Search ..."
                    class
                    v-model="search"
                    ref="searchInput"
                    @keyup.enter.native="searchPhrase"
                    size="mini"
                  >
                    <template slot="suffix">
                      <i
                        v-if="isSearching"
                        class="el-input__icon el-icon-loading"
                      ></i>
                      <i
                        v-else
                        class="el-input__icon el-icon-search"
                        @click="searchPhrase"
                      ></i>
                    </template>
                  </el-input>
                </div>
              </el-submenu>
              <el-submenu
                index="7"
                @mouseenter.native="getNotifications"
                v-if="hasAnyCrtPermission"
              >
                <template slot="title">
                  <font-awesome-icon
                    icon="bell"
                    style="font-size: 16px; text-align: center; width: 24px"
                    aria-hidden="true"
                  ></font-awesome-icon>
                  <span slot="title">USER NOTIFICATIONS</span>
                  <el-badge
                    v-if="numberOfNotifications > 0"
                    class="item"
                    :value="numberOfNotifications"
                    :is-dot="isCollapsed"
                    style="left: -3px"
                    type="primary"
                  />
                </template>
                <el-table
                  :data="notificationsList"
                  height="250"
                  size="mini"
                  :empty-text.sync="emptyNotificationsText"
                >
                  <el-table-column prop="requestId" label="Plan id" width="120">
                    <template slot-scope="scope">
                      <span>
                        <router-link
                          :to="
                            scope.row.fulfilmentPlanId
                              ? '/FulfilmentPlan/Details/' +
                                scope.row.fulfilmentPlanId
                              : '/ManufacturingCycle/Details/' +
                                scope.row.manufacturingCycleId
                          "
                          >{{ scope.row.requestId }}</router-link
                        >
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Date" width="130">
                    <template slot-scope="scope">
                      <i class="el-icon-date"></i>
                      <span style="margin-left: 10px">
                        {{ scope.row.time | moment }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="NotificationMessage"
                    label="Message"
                    width="500"
                  ></el-table-column>
                  <el-table-column label="Message" width="60">
                    <template slot="header">
                      <el-button
                        size="mini"
                        type="primary"
                        @click="acknowledgeNotifications"
                      >
                        <font-awesome-icon
                          icon="check-double"
                          style="font-size: 10px"
                          aria-hidden="true"
                        />
                      </el-button>
                    </template>
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="primary"
                        @click="
                          acknowledgeNotification(scope.row.notyficationId)
                        "
                      >
                        <font-awesome-icon
                          icon="check"
                          style="font-size: 10px"
                          aria-hidden="true"
                        />
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-submenu>
              <el-submenu index="6">
                <template slot="title">
                  <font-awesome-icon
                    icon="user-cog"
                    style="font-size: 16px; text-align: center; width: 24px"
                    aria-hidden="true"
                  />
                  <span slot="title">USER CONFIGURATION</span>
                </template>
                <el-menu-item index="6-1">
                  MENU MODE
                  <el-radio-group v-model="menuModeSelect" size="mini">
                    <el-radio-button label="AUTO"></el-radio-button>
                    <el-radio-button label="HORIZONTAL"></el-radio-button>
                    <el-radio-button label="VERTICAL"></el-radio-button>
                    <el-radio-button label="COLLAPSE"></el-radio-button>
                  </el-radio-group>
                </el-menu-item>
                <el-menu-item
                  index="6-2"
                  @click="changePasswordDialogVisible = true"
                  >CHANGE PASSWORD</el-menu-item
                >
                <div v-for="item in userMenuItems" :key="item.url">
                  <template
                    v-if="item.privilege && item.type === menuItemType.Item"
                  >
                    <router-link
                      v-if="canUseRouter(item.url)"
                      :key="item.index"
                      :to="clearPrefix(item.url)"
                      class="navbar-link"
                    >
                      <el-menu-item :index="item.index">
                        {{ item.label }}
                      </el-menu-item>
                    </router-link>
                    <router-link
                      v-else
                      :key="item.index"
                      :to="item.url"
                      class="navbar-link"
                    >
                      <el-menu-item :index="item.index">
                        {{ item.label }}
                      </el-menu-item>
                    </router-link>
                  </template>
                  <el-submenu
                    :index="item.index"
                    v-if="item.privilege && item.type === menuItemType.SubItem"
                  >
                    <template slot="title">{{ item.label }}</template>
                    <template
                      v-for="subitem in item.subItems.filter(
                        (i) => i.privilege
                      )"
                    >
                      <router-link
                        v-if="canUseRouter(subitem.url)"
                        :key="subitem.index"
                        :to="clearPrefix(subitem.url)"
                        class="navbar-link"
                      >
                        <el-menu-item :index="subitem.index">
                          {{ subitem.label }}
                        </el-menu-item>
                      </router-link>
                      <router-link
                        v-else
                        :key="subitem.index"
                        :to="subitem.url"
                        class="navbar-link"
                      >
                        <el-menu-item :index="subitem.index">
                          {{ subitem.label }}
                        </el-menu-item>
                      </router-link>
                    </template>
                  </el-submenu>
                </div>
                <a class="navbar-link" @click="signOut">
                  <el-menu-item index="6-15">LOG OUT</el-menu-item>
                </a>
              </el-submenu>
              <el-submenu index="5">
                <template slot="title">
                  <font-awesome-icon
                    icon="cogs"
                    style="font-size: 16px; text-align: center; width: 24px"
                    aria-hidden="true"
                  />
                  <span slot="title">CONFIGURATION</span>
                </template>
                <div v-for="item in configurationMenuItems" :key="item.url">
                  <template
                    v-if="item.privilege && item.type === menuItemType.Item"
                  >
                    <router-link
                      v-if="canUseRouter(item.url)"
                      :key="item.index"
                      :to="clearPrefix(item.url)"
                      class="navbar-link"
                    >
                      <el-menu-item :index="item.index">
                        {{ item.label }}
                      </el-menu-item>
                    </router-link>
                    <router-link
                      v-else
                      :key="item.index"
                      :to="item.url"
                      class="navbar-link"
                    >
                      <el-menu-item :index="item.index">
                        {{ item.label }}
                      </el-menu-item>
                    </router-link>
                  </template>
                  <el-submenu
                    :index="item.index"
                    v-if="item.privilege && item.type === menuItemType.SubItem"
                  >
                    <template slot="title">{{ item.label }}</template>
                    <div v-for="subitem in item.subItems" :key="subitem.index">
                      <template v-if="subitem.privilege">
                        <router-link
                          v-if="canUseRouter(subitem.url)"
                          :key="subitem.index"
                          :to="clearPrefix(subitem.url)"
                          class="navbar-link"
                        >
                          <el-menu-item :index="subitem.index">
                            {{ subitem.label }}
                          </el-menu-item>
                        </router-link>
                        <router-link
                          v-else
                          :key="subitem.index"
                          :to="subitem.url"
                          class="navbar-link"
                        >
                          <el-menu-item :index="subitem.index">
                            {{ subitem.label }}
                          </el-menu-item>
                        </router-link>
                      </template>
                    </div>
                  </el-submenu>
                </div>
                <el-menu-item @click="aboutModalVisible = true"
                  >ABOUT</el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div>
        </el-aside>
      </el-container>
    </el-container>
    <change-password
      :visible.sync="changePasswordDialogVisible"
    ></change-password>
    <about-dialog
      :visible.sync="aboutModalVisible"
      :show-crt="hasAnyCrtPermission"
      :show-e-doc="hasAnyEDocPermission"
    ></about-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { navBarService } from "../services/navbar.service";
import ChangePassword from "./user/ChangePassword.vue";
import AboutDialog from "./about/AboutDialog.vue";

import { HTTP } from "../services/HttpCommon";
import { DocumentationHttpClient as documentationHttpClient } from "../shared/edoc/DocumentationHttpClient";
import { ScreenType } from "../enums/ScreenType";
import { OperationResultType } from "../enums/enums";

import { MenuItemType } from "../enums/MenuItemType";

import {
  getAssignedEDocPermissions,
  getAssignedPermissions,
  getAssignedDesignSessionPermissions,
} from "../store/modules/mutationTypes";

import { mapGetters, mapActions, mapState } from "vuex";

import MenuItems from "../mixins/MenuItems";

export default {
  mixins: [MenuItems],
  components: {
    ChangePassword,
    AboutDialog,
  },
  data() {
    return {
      changePasswordDialogVisible: false,
      aboutModalVisible: false,
      activeIndex: "1",
      refreshingNotificationTable: false,
      menuMode: "horizontal",
      menuItemType: MenuItemType,
      emptyNotificationsText: "",
      windowWidth: 0,
      isCollapsed: false,
      menuModeSelect: "AUTO",
      notificationsList: [],
      isActive: true,
      error: null,
      search: null,
      mainMarginTop: "0",
      mainMarginLeft: "0",
      numberOfNotifications: 0,
      reportsList: [],
      planChangesList: [],
      manufacturingChangesList: [],
      fulfilmentPlanFiltersList: [],
      manufacturingFiltersList: [],
      documentsFiltersList: [],
      productsFiltersList: [],
      isSearching: false,
    };
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcUser", "oidcIsAuthenticated"]),
    ...mapGetters("permissions", ["allPermissionsLoaded"]),
    ...mapState("permissions", [
      "userPermissions",
      "eDocPermissions",
      "permissionsLoaded",
      "eDocPermissionsLoaded",
    ]),
    combinedOrderDefinitions() {
      return this.$store.state.combinedOrder.combinedOrderDefinitions;
    },
    combinedOrdersList() {
      const definitions = this.combinedOrderDefinitions;

      if (!!definitions && definitions.length > 0) {
        const listItems = definitions.map((d, i) => {
          return {
            label: d.name.toUpperCase(),
            url: "/CRT/CombinedOrders?chosenDefinition=" + d.id,
            index: "2-2-" + (i + 1),
            privilege: true,
          };
        });

        return listItems;
      }

      return [];
    },
    hasAnyCrtPermission() {
      return Object.keys(this.userPermissions)
        .filter((item) => item !== "CanManageUsers")
        .some((prop) => this.userPermissions[prop]);
    },
    hasAnyEDocPermission() {
      return Object.keys(this.eDocPermissions).some(
        (prop) => this.eDocPermissions[prop]
      );
    },
    navbarTitle: function () {
      var isEDocUser = !this.hasAnyCrtPermission && this.hasAnyEDocPermission;

      return isEDocUser ? "eDOC" : "CRT";
    },
  },
  watch: {
    "$route.path": function () {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    permissionsLoaded() {
      this.loadMenuData();
    },
    eDocPermissionsLoaded() {
      if (this.eDocPermissions.CanReadDocuments) {
        this.getDocumentsFilters();
      }
      if (this.eDocPermissions.CanReadProducts) {
        this.getProductsFilters();
      }

      this.setAutoMenuMode();
    },
    allPermissionsLoaded() {
      if (
        Object.values(this.userPermissions).every(
          (permission) => permission === false
        ) &&
        Object.values(this.eDocPermissions).every(
          (permission) => permission === false
        )
      ) {
        if (!this.canUseRouter(this.$route.path)) {
          window.location.href = "/CRT/PermissionsLack";
        } else if (this.$route.path !== "/PermissionsLack") {
          this.$router.push({ path: "/PermissionsLack" });
        }
      }
    },
    windowWidth(newWidth, oldWidth) {
      this.setAutoMenuMode();
    },
    menuModeSelect(newMenuModeSelect) {
      localStorage.menuModeSelect = newMenuModeSelect;
      if (this.menuModeSelect === "HORIZONTAL") {
        this.menuMode = "horizontal";
        this.isCollapsed = false;
        this.$nextTick(function () {
          setTimeout(function () {
            document.getElementById("appContainer").style.marginLeft = "0";
          }, 300);
        });
      }
      if (this.menuModeSelect === "VERTICAL") {
        this.menuMode = "vertical";
        this.isCollapsed = false;
        this.$nextTick(function () {
          setTimeout(function () {
            document.getElementById("appContainer").style.marginLeft =
              document.getElementById("asideMenu").clientWidth + "px";
          }, 300);
        });
      }
      if (this.menuModeSelect === "COLLAPSE") {
        this.menuMode = "vertical";
        this.isCollapsed = true;
        this.$nextTick(function () {
          setTimeout(function () {
            document.getElementById("appContainer").style.marginLeft =
              document.getElementById("asideMenu").clientWidth + "px";
          }, 300);
        });
      }
      if (this.menuModeSelect === "AUTO") {
        this.setAutoMenuMode();
      }
    },
    oidcIsAuthenticated(newValue) {
      if (newValue) {
        this.loadInitialData();
      }
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
    if (localStorage.menuModeSelect) {
      this.$nextTick(() => {
        this.menuModeSelect = localStorage.menuModeSelect;
      });
    }
    if (this.oidcIsAuthenticated) {
      this.loadInitialData();
    }

    if (this.permissionsLoaded) {
      this.loadMenuData();
    }
    if (this.eDocPermissionsLoaded) {
      if (this.eDocPermissions.CanReadDocuments) {
        this.getDocumentsFilters();
      }
      if (this.eDocPermissions.CanReadProducts) {
        this.getProductsFilters();
      }

      this.setAutoMenuMode();
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  methods: {
    ...mapActions("oidcStore", ["signOutOidc", "clearStaleState"]),
    ...mapActions("combinedOrder", ["getCombinedOrderDefinitions"]),
    ...mapActions("permissions", [
      getAssignedEDocPermissions,
      getAssignedPermissions,
      getAssignedDesignSessionPermissions,
    ]),
    loadInitialData() {
      this.getCombinedOrderDefinitions();
      this.getAssignedPermissions();
      this.getAssignedDesignSessionPermissions();
      this.getAssignedEDocPermissions();
    },
    loadMenuData() {
      this.getReports();
      this.getNumberOfNotifications();
      this.getFulfilmentPlanFilters();
      this.getManufacturingFilters();
      this.getChangesFilters();
      this.setAutoMenuMode();
    },
    signOut() {
      this.clearStaleState();
      this.signOutOidc();
    },
    canUseRouter(url) {
      let route = this.$router.resolve(this.clearPrefix(url));

      if (
        route.resolved.matched.filter((item) => item.path !== "*").length > 0 &&
        this.$route.matched.filter((item) => item.path !== "*").length > 0
      ) {
        return true;
      }
      return false;
    },
    clearPrefix(url) {
      return url.replace("/CRT", "");
    },
    getNumberOfNotifications() {
      navBarService.getNumberOfNotifications((data) => {
        this.numberOfNotifications = data;
      });
    },
    getScreenType(path) {
      if (path.startsWith("/ManufacturingCycle")) {
        return ScreenType.ManufacturingCycle;
      } else if (path.startsWith("/FulfilmentPlan")) {
        return ScreenType.FulfilmentPlan;
      } else {
        return ScreenType.RepeatOrder;
      }
    },
    searchPhrase() {
      if (this.isSearching) return;
      this.isSearching = true;
      const pathName = this.$route.path;
      const data = this.search;
      if (pathName.startsWith("/Documentation/Document")) {
        documentationHttpClient
          .get(`/Document/Search?phrase=${data}`)
          .then((result) => {
            this.isSearching = false;
            if (result.data.isSuccess) {
              this.$router.push({
                path: `/Documentation/Document/${result.data.id}`,
              });
            } else {
              this.$message({
                message: result.data.message,
                type: "error",
              });
            }
          });
      } else if (pathName.startsWith("/Documentation/Product")) {
        documentationHttpClient
          .get(`/Product/Search?phrase=${data}`)
          .then((result) => {
            this.isSearching = false;
            if (result.data.isSuccess) {
              this.$router.push({
                path: `/Documentation/Product/${result.data.id}`,
              });
            } else {
              this.$message({
                message: result.data.message,
                type: "error",
              });
            }
          });
      } else if (pathName.startsWith("/Documentation")) {
        documentationHttpClient
          .get(`/Document/Search?phrase=${data}`)
          .then((documentResult) => {
            this.isSearching = false;
            if (documentResult.data.isSuccess) {
              this.$router.push({
                path: `/Documentation/Document/${documentResult.data.id}`,
              });
            } else {
              documentationHttpClient
                .get(`/Product/Search?phrase=${data}`)
                .then((productResult) => {
                  if (productResult.data.isSuccess) {
                    this.$router.push({
                      path: `/Documentation/Product/${productResult.data.id}`,
                    });
                  } else {
                    this.$message({
                      message: `${documentResult.data.message} ${productResult.data.message}`,
                      type: "error",
                    });
                  }
                });
            }
          });
      } else {
        HTTP.post("/Search/SearchPhrase", {
          phrase: data,
          screenType: this.getScreenType(pathName),
        }).then((result) => {
          this.isSearching = false;
          if (result.data.result === OperationResultType.Success) {
            if (result.data.screenType == ScreenType.ManufacturingCycle) {
              this.$router.push({
                path: `/ManufacturingCycle/Details/${result.data.fulfilmentPlanId}?useContext=true`,
              });
            } else if (result.data.screenType == ScreenType.PrototypeManufacturingCycle) {
              this.$router.push({
                path: `/PrototypeManufacturingCycle/Details/${result.data.fulfilmentPlanId}?useContext=true`,
              });
            } else if (result.data.screenType == ScreenType.FulfilmentPlan) {
              this.$router.push({
                path: `/FulfilmentPlan/Details/${result.data.fulfilmentPlanId}?useContext=true`,
              });
            } else {
              this.$router.push({
                path: `/RepeatOrders/${result.data.fulfilmentPlanId}?useContext=true`,
              });
            }
          } else {
            this.$message({
              message: result.data.message,
              type: "error",
            });
          }
        });
      }
    },
    getNotifications() {
      this.$nextTick(function () {
        this.emptyNotificationsText = "Loading...";
      });
      this.getNumberOfNotifications();
      navBarService.getNotifications((data) => {
        this.notificationsList = data.content;
        this.emptyNotificationsText = "No notifications available";
      });
    },
    acknowledgeNotifications() {
      navBarService.acknowledgeNotifications((data) => {
        this.$message({
          message: "Notifications acknowledged succesfully",
          type: "info",
        });
        this.getNotifications();
      });
    },
    acknowledgeNotification(id) {
      navBarService.acknowledgeNotification(id, (data) => {
        this.$message({
          message: "Notification acknowledged succesfully",
          type: "info",
        });
        this.getNotifications();
      });
    },
    getReports() {
      navBarService.getReports((data) => {
        if (data !== null && data.items.length > 0) {
          for (var i = 0; i < data.items.length; i++) {
            this.reportsList.push({
              label: data.items[i].name.toUpperCase(),
              type: 1,
              url: "/CRT/Reports/EmbeddedReport/" + data.items[i].id,
              index: "4-" + (i + 1),
              privilege: true,
            });
          }
        }
      });
    },
    getFulfilmentPlanFilters() {
      navBarService.getFulfilmentPlanFilters((data) => {
        this.fulfilmentPlanFiltersList.push({
          label: "DEFAULT",
          url: "/FulfilmentPlan/List",
          index: "1-1-1",
          privilege:
            this.userPermissions.CanManageFulfilmentPlan ||
            this.userPermissions.ReadOnlyFulfilmentPlanAccess,
        });
        if (data !== null && data.content.length > 0) {
          for (var i = 0; i < data.content.length; i++) {
            this.fulfilmentPlanFiltersList.push({
              label: data.content[i].name.toUpperCase(),
              url: "/FulfilmentPlan/LoadFilter/" + data.content[i].id,
              index: "1-1-" + (i + 2),
              privilege: true,
              icon:
                data.content[i].sharingLevel === 0 ? "user" : "globe-americas",
            });
          }
        }
      });
    },
    getManufacturingFilters() {
      navBarService.getManufacturingFilters((data) => {
        this.manufacturingFiltersList.push({
          label: "DEFAULT",
          url: "/ManufacturingCycle/List",
          index: "2-1-1",
          privilege:
            this.userPermissions.CanManageFulfilmentPlan ||
            this.userPermissions.ReadOnlyFulfilmentPlanAccess,
        });
        if (data !== null && data.content.length > 0) {
          for (var i = 0; i < data.content.length; i++) {
            this.manufacturingFiltersList.push({
              label: data.content[i].name.toUpperCase(),
              url: "/ManufacturingCycle/LoadFilter/" + data.content[i].id,
              index: "2-1-" + (i + 2),
              privilege: true,
              icon:
                data.content[i].sharingLevel === 0 ? "user" : "globe-americas",
            });
          }
        }
      });
    },
    getChangesFilters() {
      navBarService.getChangesFilters((data) => {
        this.planChangesList.push({
          label: "DEFAULT",
          url: "/CRT/Changes",
          index: "1-2-1",
          privilege:
            this.userPermissions.CanManageFulfilmentPlan ||
            this.userPermissions.ReadOnlyFulfilmentPlanAccess,
        });
        this.manufacturingChangesList.push({
          label: "DEFAULT",
          url: "/CRT/Changes",
          index: "2-3-1",
          privilege:
            this.userPermissions.CanManageFulfilmentPlan ||
            this.userPermissions.ReadOnlyFulfilmentPlanAccess,
        });
        if (data !== null && data.content.length > 0) {
          for (var i = 0; i < data.content.length; i++) {
            this.planChangesList.push({
              label: data.content[i].name.toUpperCase(),
              url: "/CRT/Changes?storedQueryId=" + data.content[i].id,
              index: "1-3-" + (i + 2),
              privilege: true,
              icon:
                data.content[i].sharingLevel === 0 ? "user" : "globe-americas",
            });
            this.manufacturingChangesList.push({
              label: data.content[i].name.toUpperCase(),
              url: "/CRT/Changes?storedQueryId=" + data.content[i].id,
              index: "2-3-" + (i + 2),
              privilege: true,
              icon:
                data.content[i].sharingLevel === 0 ? "user" : "globe-americas",
            });
          }
        }
      });
    },
    getDocumentsFilters() {
      navBarService.getDocumentsFilters((data) => {
        this.documentsFiltersList.push({
          label: "ALL",
          url: "/CRT/Documentation/Document?handleFilter=1",
          index: "9-1-1",
          privilege: this.eDocPermissions.CanReadDocuments,
        });
        if (data !== null && data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            this.documentsFiltersList.push({
              label: data[i].name.toUpperCase(),
              url:
                `/CRT/Documentation/Document?handleFilter=1&` + data[i].query,
              index: "9-1-" + (i + 2),
              privilege: true,
              icon: data[i].isPrivate ? "user" : "globe-americas",
            });
          }
        }
      });
    },
    getProductsFilters() {
      navBarService.getProductsFilters((data) => {
        this.productsFiltersList.push({
          label: "ALL",
          url: "/CRT/Documentation/Product?handleFilter=1",
          index: "10-1-1",
          privilege: this.eDocPermissions.CanReadProducts,
        });
        if (data !== null && data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            this.productsFiltersList.push({
              label: data[i].name.toUpperCase(),
              url: "/CRT/Documentation/Product?handleFilter=1&" + data[i].query,
              index: "10-1-" + (i + 2),
              privilege: true,
              icon: data[i].isPrivate ? "user" : "globe-americas",
            });
          }
        }
      });
    },
    setAutoMenuMode() {
      if (this.menuModeSelect === "AUTO") {
        if (
          this.windowWidth <
            (this.hasAnyEDocPermission && this.hasAnyCrtPermission
              ? 1870
              : 1320) &&
          this.windowWidth >= 1000
        ) {
          this.menuMode = "vertical";
          this.isCollapsed = false;
          document.getElementById("appContainer").style.marginTop = "0";
          this.$nextTick(function () {
            document.getElementById("appContainer").style.marginLeft =
              document.getElementById("asideMenu").clientWidth + "px";
          });
        } else if (this.windowWidth < 1000) {
          this.menuMode = "vertical";
          this.isCollapsed = true;
          document.getElementById("appContainer").style.marginTop = "0";
          this.$nextTick(function () {
            document.getElementById("appContainer").style.marginLeft =
              document.getElementById("asideMenu").clientWidth + "px";
          });
        } else {
          this.menuMode = "horizontal";
          this.isCollapsed = false;

          if (document.getElementById("appContainer") !== null) {
            document.getElementById("appContainer").style.marginLeft = "0";
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.el-aside::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.menuMaxWidth {
  min-width: 250px;
}

.el-menu--popup {
  z-index: 100;
  min-width: 225px;
  border: none;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.el-submenu__title i {
  color: #fff;
}

$scrollbar-width: 0.5em;
$scrollbar-color: rgba(255, 255, 255, 0.4);
$scrollbar-hover-color: rgba(255, 255, 255, 1);
$scrollbar-radius: 0.5em;
.el-menu--popup {
  max-height: 70vh;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: $scrollbar-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar-hover-color;
  }

  &::-moz-scrollbar {
    width: $scrollbar-width;
  }

  &::-moz-scrollbar-track {
    background-color: transparent;
  }

  &::-moz-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: $scrollbar-radius;
  }

  &::-moz-scrollbar-thumb:hover {
    background-color: $scrollbar-hover-color;
  }
}
</style>
